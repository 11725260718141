import React, { useEffect, useState } from "react";
import { images } from "../helpers/images";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutStatic } from "../redux/features/authSlice";
import { toast } from "react-toastify";

const NavBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profilePic, setProfilePic] = useState(null);

  // const { user } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  console.log({ user });

  const currentTab = location.pathname.substring(1) || "dashboard";
  const [activeTab, setActiveTab] = useState(currentTab);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [profileOptions, setProfileOptions] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleProfileOptions = () => {
    setProfileOptions(!profileOptions);
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(logoutStatic());
    toast.success("Logout Successful");
    navigate("/");
  };
  const BaseURL = process.env.REACT_APP_BASEURL;
  useEffect(() => {
    if (user) {
      let UserPic = BaseURL.replace(/\/api$/, "") + "/" + user.ProfilePic;
      if (user.ProfilePic) {
        setProfilePic(UserPic);
      }
    }
  }, []);

  return (
    <div>
      <nav className="bg-blue2 border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
          <Link className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={images.Xpireelogo} className="h-8" alt="Flowbite Logo" />
            {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Flowbite
            </span> */}
          </Link>
          <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse relative">
            {/* <button
              type="button"
              //   className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              id="user-menu-button"
              aria-expanded="false"
              data-dropdown-toggle="user-dropdown"
              data-dropdown-placement="bottom"
            >
              <img
                className="w-[24px] h-[24px] rounded-full mr-6"
                src={images.settings_icon}
                alt="setting"
              />
            </button> */}
            <button
              type="button"
              className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              id="user-menu-button"
              aria-expanded="false"
              data-dropdown-toggle="user-dropdown"
              data-dropdown-placement="bottom"
              onClick={toggleProfileOptions}
            >
              <span className="sr-only">Open user menu</span>
              {profilePic ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={profilePic}
                  alt="-"
                />
              ) : (
                <div class="relative bg-primary6 border-2 border-white  rounded-full inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-500  dark:bg-gray-600">
                  <span class="font-medium text-gray-600 dark:text-gray-300">
                    {user?.Name[0]}
                  </span>
                </div>
              )}
            </button>
            {/* Dropdown menu */}
            <div
              className={`z-50 absolute top-5 right-0 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 ${
                profileOptions ? "hidden" : "" // hide if collapsed
              }`}
              id="user-dropdown"
            >
              <div className="px-4 py-3">
                <span className="block text-sm font-extrabold uppercase text-primary5 dark:text-blue3">
                  {user?.Name}
                </span>
                <span className="block text-sm mt-1 truncate text-primary5 dark:text-gray-400">
                  {user?.Email}
                </span>
              </div>
              <ul className="py-2" aria-labelledby="user-menu-button">
                <li>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Dashboard
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Settings
                  </Link>
                </li> */}
                <li></li>
                <li>
                  <Link
                    to="/documents"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Documents
                  </Link>
                  <NavLink
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    onClick={logoutHandler}
                    id="logout"
                  >
                    Sign out
                  </NavLink>
                </li>
              </ul>
            </div>
            <button
              data-collapse-toggle="navbar-user"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-user"
              //   aria-expanded="false"
              aria-expanded={!isCollapsed}
              onClick={toggleNavbar}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            // className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              isCollapsed ? "hidden" : "" // hide if collapsed
            }`}
            id="navbar-user"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg   rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to="/dashboard"
                  className="x-navItem "
                  onClick={() => setActiveTab("dashboard")}
                  style={{
                    backgroundColor:
                      activeTab === "dashboard" ? "white" : "inherit",
                    color: activeTab === "dashboard" ? "#464E5F" : "white",
                  }}
                  aria-current="page"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/documents"
                  className="x-navItem "
                  onClick={() => setActiveTab("documents")}
                  style={{
                    backgroundColor:
                      activeTab === "documents" ? "white" : "inherit",
                    color: activeTab === "documents" ? "#464E5F" : "white",
                  }}
                >
                  Documents
                </Link>
              </li>
              <li>
                <Link
                  to="/calendar"
                  className="x-navItem"
                  onClick={() => setActiveTab("calendar")}
                  style={{
                    backgroundColor:
                      activeTab === "calendar" ? "white" : "inherit",
                    color: activeTab === "calendar" ? "#464E5F" : "white",
                  }}
                >
                  Calendar
                </Link>
              </li>
              <li>
                <Link
                  to="/folders"
                  className="x-navItem"
                  onClick={() => setActiveTab("folders")}
                  style={{
                    backgroundColor:
                      activeTab === "folders" ? "white" : "inherit",
                    color: activeTab === "folders" ? "#464E5F" : "white",
                  }}
                >
                  Folders
                </Link>
              </li>
              <li>
                <Link
                  to="/task"
                  className="x-navItem"
                  onClick={() => setActiveTab("task")}
                  style={{
                    backgroundColor: activeTab === "task" ? "white" : "inherit",
                    color: activeTab === "task" ? "#464E5F" : "white",
                  }}
                >
                  Tasks
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
