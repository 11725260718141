import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../Layout/Navbar";
import InfoBoard from "./infoBoard/InfoBoard";
import { images } from "../../helpers/images";
import cs from "./folders.module.scss";
import CreateFolder from "../../components/Modals/CreateFolder";
import FolderServices from "../../services/api/folder.service";
import { toast } from "react-toastify";
import { SelectFolder } from "../../redux/features/folderSlice";
import { useNavigate } from "react-router-dom";

const Folders = () => {
  const [folderData, setFolderData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getFoldersData();
  }, []);

  const getFoldersData = useCallback(() => {
    setLoading(true);
    FolderServices.GetFolders()
      .then((resp) => {
        setLoading(false);
        if (resp.status == 200) {
          setFolderData(resp.data.result);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Failed");
      });
  });

  const confirmDelete = (folder) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this folder?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `Don't delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log(folder);
        FolderServices.deleteFolder(folder.Id)
          .then((resp) => {
            if (resp.status == 200) {
              Swal.fire("Folder Deleted!", "", "success");
              getFoldersData();
            }
          })
          .catch((err) => {
            toast.error("Failed");
          });
      }
    });
  };

  return (
    <>
      <NavBar />
      <CreateFolder getFoldersData={() => getFoldersData()} />
      {/* flex flex-wrap items-center justify-between */}
      <div className="max-w-screen-xl  mx-auto py-7">
        <div className="h-full bg-gray1">
          <InfoBoard folderData={folderData} />

          {!loading && folderData?.length > 0 && (
            <div className=" my-12 flex flex-col  py-[50px] px-5">
              <p className="h2-text mb-[40px]"> All Folders</p>

              <section className={`${cs.FlexFolders}`}>
                {folderData &&
                  folderData.map((folder) => (
                    <div
                      className={cs.FolderCard}
                      style={{
                        borderBottom: "5px solid",
                        borderColor: folder?.Color,
                      }}
                    >
                      <img
                        src={images.folder}
                        alt="img"

                        // className="w-[56px] h-[56px]"
                      />
                      <p className="h3-text mt-[26px] mb-[5px]">
                        {folder?.Name}
                      </p>
                      <p className="p2-text">{folder?.docCount} Documents</p>
                      {/* <img src={images.menu_vertical_icon} alt="img" /> */}
                      <div className={cs.menu_icon}>
                        <div className={`dropdown dropdown-end `}>
                          <div tabIndex={0} role="button">
                            <img src={images.menu_vertical_icon} alt="img" />
                          </div>
                          <ul
                            tabIndex={0}
                            className="dropdown-content z-[1] menu p-2 shadow bg-white  rounded-box w-36"
                          >
                            <li
                              onClick={() => {
                                dispatch(SelectFolder(folder));
                                document
                                  .getElementById("create_folder_modal")
                                  .showModal();
                              }}
                            >
                              <p>Edit</p>
                            </li>
                            <li onClick={() => confirmDelete(folder)}>
                              <p>Delete</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* <div className={cs.FolderCard}>
                <img
                  src={images.folder}
                  alt="img"
                  // className="w-[56px] h-[56px]"
                />
                <p className="h3-text mt-[26px] mb-[5px]">My Docs</p>
                <p className="p2-text">Total Folders</p>
                <img
                  src={images.menu_vertical_icon}
                  className={cs.menu_icon}
                  alt="img"
                />
              </div>
              <div className={cs.FolderCard}>
                <img src={images.folder} alt="img" />
                <p className="h3-text mt-[26px] mb-[5px]">Health</p>
                <p className="p2-text">Total Folders</p>
                <img
                  src={images.menu_vertical_icon}
                  className={cs.menu_icon}
                  alt="img"
                />
              </div>
              <div className={cs.FolderCard}>
                <img src={images.folder} alt="img" />
                <p className="h3-text mt-[26px] mb-[5px]">Medical</p>
                <p className="p2-text">Total Folders</p>
                <img
                  src={images.menu_vertical_icon}
                  className={cs.menu_icon}
                  alt="img"
                />
              </div>
              <div className={cs.FolderCard}>
                <img src={images.folder} alt="img" />
                <p className="h3-text mt-[26px] mb-[5px]">Important Docs</p>
                <p className="p2-text">Total Folders</p>
                <img
                  src={images.menu_vertical_icon}
                  className={cs.menu_icon}
                  alt="img"
                />
              </div>
              <div className={cs.FolderCard}>
                <img src={images.folder} alt="img" />
                <p className="h3-text mt-[26px] mb-[5px]">Important Docs</p>
                <p className="p2-text">Total Folders</p>
                <img
                  src={images.menu_vertical_icon}
                  className={cs.menu_icon}
                  alt="img"
                />
              </div> */}
              </section>
            </div>
          )}

          {!loading && folderData?.length == 0 && (
            <div className=" bg-white my-6 rounded-[12px] flex flex-col justify-center items-center py-[90px]">
              <img
                src={images.ImgPlaceholder2}
                alt="img"
                className="w-[175px] "
              />
              <p className="h2-text">Let’s Get Started</p>
              <p className="p2-text my-1">
                Start by adding a folder to track expiration
              </p>
              <button
                onClick={() => {
                  dispatch(SelectFolder(null));
                  document.getElementById("create_folder_modal").showModal();
                }}
                className="btn btn-wide text-white bg-blue1 border-none mt-[60px]"
              >
                Add New Folder
              </button>
            </div>
          )}

          {loading && (
            <div className=" h-100  my-12 rounded-[12px] flex flex-col justify-center items-center py-[250px]">
              <p className="p2-text my-1">Loading...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Folders;
