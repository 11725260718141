import React, { useState, useEffect, useCallback } from "react";

import NavBar from "../../Layout/Navbar";
import InfoBoard from "./infoBoard/InfoBoard";
import { images } from "../../helpers/images";
import { H2 } from "../../components/common/loader/TypoGraphy/TypoGraphy";
import TaskTable from "./TaskTable";
import { useNavigate } from "react-router-dom";
import DocumentServices from "../../services/api/document.service";
import TaskServices from "../../services/api/task.service";

const TaskPage = () => {
  const navigate = useNavigate();

  const [taskData, setTaskData] = useState([]);
  const [taskByMe, setTaskByMe] = useState([]);
  const [taskCompleted, setTaskompleted] = useState([]);

  useEffect(() => {
    getGetAssignedToMeTaskData();
    getAssignedByMeTaskData();
    getCompletedTaskData();
  }, []);

  const getGetAssignedToMeTaskData = useCallback(() => {
    TaskServices.GetAssignedToMe()
      .then((resp) => {
        if (resp.status == 200) {
          setTaskData(resp.data.result);
        }
      })
      .catch((err) => {});
  });

  const getAssignedByMeTaskData = useCallback(() => {
    TaskServices.GetAssignedByMe()
      .then((resp) => {
        if (resp.status == 200) {
          setTaskByMe(resp.data.result);
        }
      })
      .catch((err) => {});
  });

  const getCompletedTaskData = useCallback(() => {
    TaskServices.GetCompletedTask()
      .then((resp) => {
        if (resp.status == 200) {
          setTaskompleted(resp.data.result);
        }
      })
      .catch((err) => {});
  });

  return (
    <>
      <NavBar />

      {/* flex flex-wrap items-center justify-between */}
      <div className="max-w-screen-xl  mx-auto py-7">
        <div className="h-full bg-gray1">
          <InfoBoard
            AssignedToMe={taskData}
            TaskByMe={taskByMe}
            TaskCompleted={taskCompleted}
          />
          {taskData?.length == 0 ? (
            <div className=" bg-white my-6 rounded-[12px] flex flex-col justify-center items-center py-[90px]">
              <p className="text-primary5 font-medium opacity-50 my-1">
                No Task Assigned
              </p>
            </div>
          ) : (
            <div className=" bg-white my-12 rounded-[12px] flex flex-col  p-[30px]">
              <div className="flex justify-between ">
                <H2>My Tasks</H2>
                <div className="flex ">
                  <label
                    style={{ background: "white" }}
                    className="input input-bordered flex items-center gap-2 h-[34px]   "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="w-4 h-4 opacity-70"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <input
                      type="text"
                      className="w-[135px] text-[12px] "
                      // style={{ background: "white" }}
                      placeholder="Search"
                    />
                  </label>
                  {/* <details className="dropdown">
                  <summary className=" bg-white w-[122px] h-[34px]">
                    View All
                  </summary>
                  <ul className="p-2 bg-white shadow menu dropdown-content z-[1]  rounded-box w-52">
                    <li>
                      <a>Item 1</a>
                    </li>
                    <li>
                      <a>Item 2</a>
                    </li>
                  </ul>
                </details> */}
                </div>
              </div>

              <TaskTable
                taskData={taskData}
                getGetAssignedToMeTaskData={() => getGetAssignedToMeTaskData()}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TaskPage;
