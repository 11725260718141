import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createSharerValidation,
  createTaskValidation,
} from "../helpers/validations";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import TaskServices from "../services/api/task.service";
import Swal from "sweetalert2";
import SharerServices from "../services/api/sharer.service";

const useSharerSubmit = (refetchSharer) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(createSharerValidation),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  // Updating values after component mounts or some event

  const CreateSharerSubmitHandler = (payload) => {
    // const payload = {
    //   ...data,
    //   DocId: id,
    //   AssignTo: data.AssignTo || null,
    // };

    setLoading(true);
    SharerServices.inviteSharer(payload)
      .then((resp) => {
        setLoading(false);
        if (resp.status == 200 && resp.data) {
          if (resp.data == 1) {
            refetchSharer();
            toast.success("Invite has been sent Successfully!");
          }
          if (resp.data == "-3") {
            toast.success("Sharer Already Exists!");
          }
          if (resp.data == "-4") {
            toast.success("You are entering your own email!");
          }
          if (resp.data == "-5") {
            toast.success(
              "You have limited No. of resources for adding Sharer! Please upgrade package!"
            );
          }
          if (resp.data == "4") {
            Swal.fire({
              title: "Invitation Sent!",
              text: "We've invited your friend via email to become your Sharer. Once invitation accepted, he will appear active in your sharer list.",
              icon: "success",
            });
          }

          reset();
          document.getElementById("invite_sharer_modal").close();
          // document.getElementById("manage_sharer_modal").close();
        }
      })
      .catch((err) => {
        setLoading(false);

        document.getElementById("invite_sharer_modal").close();
        // document.getElementById("manage_sharer_modal").close();
      });
  };

  const UpdateSharerSubmitHandler = (payload) => {
    // const payload = {
    //   ...data,
    //   DocId: id,
    //   AssignTo: data.AssignTo || null,
    // };
    setLoading(true);
    SharerServices.inviteSharer(payload)
      .then((resp) => {
        setLoading(false);
        if (resp.status == 200) {
          toast.success("Invite has been sent Successfully!");

          reset();
          document.getElementById("invite_sharer_modal").close();
          document.getElementById("manage_sharer_modal").close();
        }
      })
      .catch((err) => {
        setLoading(false);

        document.getElementById("invite_sharer_modal").close();
        document.getElementById("manage_sharer_modal").close();
      });
  };

  return {
    register,
    handleSubmit,
    errors,
    isValid,
    CreateSharerSubmitHandler,
    UpdateSharerSubmitHandler,
    loading,
    reset,
    setValue,
  };
};

export default useSharerSubmit;
