import { client } from "../interfaces/api-urls";
import { getRequest, postRequest } from "../interfaces/axios-methods";

const SharerServices = {
  GetSharers: async () => {
    return getRequest(client.GET_SHARER_DATA_TABLE + "?username=");
  },

  inviteSharer: async (body) => {
    return postRequest(
      client.ADD_SHARER + "?name=" + body?.name + "&email=" + body?.email
    );
  },

  deleteSharerInvitation: async (id) => {
    return getRequest(client.DELETE_SHARER_INVITATION + "?Id=" + id);
  },
};

export default SharerServices;
