import { useState } from "react";
import { getAllUsers } from "../services/api/user.service";
import { toast } from "react-toastify";
import { USER } from "../services/interfaces/messages";
import { client, users } from "../services/interfaces/api-urls";
import { useForm } from "react-hook-form";
import { updateUserValidation } from "../helpers/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { store } from "../redux/store";
import AuthServices from "../services/api/auth.service";
import { updateUser } from "../redux/features/userSlice";

const useUser = (uploadedFiles) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //     getAllUser();
  //   }, []);

  const getAllUser = async () => {
    const resp = await getAllUsers();
    setUserData(resp);
  };

  const fetchAllUsers = async () => {
    const resp = await getAllUsers();
    setUserData(resp.data);
    toast.success(USER.get_success);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(updateUserValidation),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { user } = useSelector((state) => state.auth);

  const updateUserSubmitHandler = (data) => {
    const formData = new FormData();
    formData.append("Name", data.Name);
    // formData.append("DocExpiringCriteria", data.DocExpiringCriteria);
    // formData.append("Email", data.Email);
    formData.append("PhoneNumber", data.PhoneNumber);
    // formData.append("CountryCode", data.CountryCode);
    formData.append("CountryId", data.CountryId);
    // formData.append("CountryName", data.CountryName);
    formData.append("file", data.ProfilePic[0]);
    // if (file != undefined) {
    //   formData.append("ProfilePic", data.ProfilePic);
    //   formData.append("file", file);
    // }

    const BaseURL = process.env.REACT_APP_BASEURL;

    setLoading(true);
    fetch(`${BaseURL}/${users.UPDATE_USER_INFO}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store.getState()?.auth?.user?.accessToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data == 1) {
          Swal.fire({
            title: "Updated!",
            text: "User Info updated successfully!",
            icon: "success",
          });
          getUserData();
          // window.location.reload();

          return;
        }

        // navigate("/documents");
      })
      .catch((error) => {
        toast.error("Failed to add Document!");
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const getUserData = () => {
    AuthServices.getUserInfo()
      .then((resp) => {
        if (resp.status == 200 || resp.data) {
          localStorage.setItem("userData", JSON.stringify(resp.data));
          dispatch(updateUser(resp.data));
          window.location.reload();
          // setUserInfo(resp.data);
          // UserPic = BaseURL.replace(/\/api$/, "") + "/" + resp.data.ProfilePic;
          // if (resp.data.ProfilePic) {
          //   setProfilePic(UserPic);
          // }
          // console.log(UserPic);
        }
      })
      .catch((err) => {});
  };

  return {
    userData,
    getAllUser,
    fetchAllUsers,
    updateUserSubmitHandler,
    register,
    handleSubmit,
    errors,

    loading,
    watch,
  };
};

export default useUser;
