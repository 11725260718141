import React, { useState, useEffect, useCallback } from "react";
import useSharerData from "../../hooks/useSharer";
import Swal from "sweetalert2";
import SharerServices from "../../services/api/sharer.service";

import { useDispatch } from "react-redux";
import { SelectSharer } from "../../redux/features/sharerSlice";
import InviteSharerModel from "./InviteSharerModel";

const ManageSharerModel = () => {
  const [sharerData, setSharerData] = useState(null);

  const { getSharerData } = useSharerData(setSharerData);
  const dispatch = useDispatch();

  useEffect(() => {
    getSharerData();
  }, []);

  const refetchSharer = () => {
    console.log("Refetched");
    getSharerData();
  };

  const editSharer = (item) => {
    dispatch(SelectSharer(item));
    document.getElementById("invite_sharer_modal").showModal();
  };

  const confirmDelete = (item) => {
    document.getElementById("manage_sharer_modal").close();
    Swal.fire({
      title: "Are you sure?",
      html: `The Sharer "<b>${item?.Name}</b>" will be removed permenantly from the account.`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `Don't delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        SharerServices.deleteSharerInvitation(item.Id)
          .then((resp) => {
            if (resp.status == 200) {
              Swal.fire("Invitation Removed", "", "success");
            }
          })
          .catch((err) => {});
      }
    });
  };

  return (
    <>
      <InviteSharerModel refetchSharer={refetchSharer} />
      <dialog id="manage_sharer_modal" className="modal">
        <div className="modal-box w-11/12 max-w-xl bg-white  p-[55px] ">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <section>
            <p className="text-[26px] font-roboto font-bold text-gray5 ">
              Manage Sharers
            </p>
            <p className="text-[16px] font-roboto mt-2 text-gray3">
              Add your mates to your team and start collaborating on keeping
              house in order.
            </p>
            <button
              onClick={() =>
                document.getElementById("invite_sharer_modal").showModal()
              }
              className="rounded-[6px] h-[42px] py-[0] w-[160px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] mt-[30px] "
            >
              Invite a Sharer
            </button>

            <section className="mt-[46px]">
              {sharerData &&
                sharerData?.map((item) => (
                  <div className="flex flex-wrap gap-y-3 justify-between items-center mb-[25px]">
                    <div className="flex">
                      {/* <img
                      src={images.person}
                      alt="save"
                      className="mr-[16px]"
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "20px",
                      }}
                    /> */}
                      <div class="relative bg-primary6 border-2 border-white mr-[16px] rounded-full inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-500  dark:bg-gray-600">
                        <span class="font-medium text-gray-600 dark:text-gray-300">
                          {item?.Name[0]}
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <p className="text-primary5 text-[16px] font-medium ">
                          {item?.Name}
                        </p>
                        <div className="flex gap-x-[20px]">
                          {/* <p className="text-xred text-[13px] font-medium ">Overdue</p> */}
                          <p className=" text-[13px] font-medium ">
                            {item?.Email}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex ">
                      <p
                        onClick={() => confirmDelete(item)}
                        className=" text-[12px] font-medium py-[10px]  px-[16px] mr-2 cursor-pointer"
                      >
                        Delete
                      </p>
                      <p
                        onClick={() => editSharer(item)}
                        className=" text-[12px] font-medium py-[10px] px-[16px] bg-primary8 rounded-[6px] cursor-pointer "
                      >
                        Edit
                      </p>
                    </div>
                  </div>
                ))}
            </section>
          </section>
        </div>
      </dialog>
    </>
  );
};

export default ManageSharerModel;
