import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import {
  H2,
  H3,
  H4,
  Text2,
} from "../../components/common/loader/TypoGraphy/TypoGraphy.js";
import NavBar from "../../Layout/Navbar.jsx";
import { Link, useLocation } from "react-router-dom";
import InfoBoard from "./infoBoard/InfoBoard.jsx";
import cs from "./viewdocument.module.scss";
import { images } from "../../helpers/images.js";
import LabelDoc from "../../components/form/LabelDoc.jsx";
import TaskServices from "../../services/api/task.service.js";
import { toast } from "react-toastify";
import useTaskSubmit from "../../hooks/useTaskSubmit.js";
import Error from "../../components/form/Error.jsx";
import DDLServices from "../../services/api/ddl.service.js";
import DocumentServices from "../../services/api/document.service.js";
import { bytesToMB } from "../../helpers/bytesToMB.js";

const DocumentDetails = ({ doc }) => {
  return (
    <div>
      <p className="h2-text">Document Details</p>
      <div className="flex flex-row flex-wrap gap-x-40 w-100  mt-5">
        <div className="flex flex-col w-[200px] ">
          <p className="p2-text ">Document Type</p>
          <p className="p3-text text-gray6 mt-1 ">{doc.DocTypeTitle ?? "--"}</p>
        </div>
        <div className="flex flex-col ">
          <p className="p2-text ">Document Number</p>
          <p className="p3-text mt-1 text-gray6">{doc?.DocNumber ?? "--"}</p>
        </div>
      </div>
      <div className="flex flex-row flex-wrap w-100 gap-x-40 mt-5">
        <div className="flex flex-col w-[200px] ">
          <p className="p2-text ">Issued Country</p>
          <p className="p3-text text-gray6 mt-1 ">{doc?.CountryId ?? "--"}</p>
        </div>
        <div className="flex flex-col ">
          <p className="p2-text ">Issued City</p>
          <p className="p3-text mt-1 text-gray6">{doc?.StateId ?? "--"}</p>
        </div>
      </div>

      <div>
        <p className="p2-text mt-5">Reminders</p>
        <div className="flex flex-wrap items-center w-[400px] gap-[10px] mt-1 ">
          <p className={cs.reminder_badge}>02 Weeks</p>
          <p className={cs.reminder_badge}>04 Weeks</p>
          <p className={cs.reminder_badge}>04 Months</p>
          <p className={cs.reminder_badge}>06 Months</p>
        </div>
        <div className="flex flex-wrap items-center w-[400px] gap-[10px] mt-3 ">
          {doc?.reminderList
            ?.filter((x) => x.CustomRemindValue)
            .map((item) => (
              <p
                className={`${cs.reminder_badge} bg-blue2 `}
                style={{ color: "white" }}
              >
                {item?.CustomRemindValue} {item?.CustomRemindPeriod}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

const ExpirationStatus = ({ doc }) => {
  return (
    <div>
      <p className="h2-text">Expiration Status</p>
      <div className="flex flex-row flex-wrap gap-x-[30px] w-100 mt-4">
        <div className="flex items-center ">
          <div className="bg-xred w-[14px] h-[14px] rounded-full mr-[10px]" />
          <p className="p2-text ">Expired</p>
        </div>
        <div className="flex items-center ">
          <div className="bg-xyellow w-[14px] h-[14px] rounded-full mr-[10px]" />
          <p className="p2-text ">Expiring</p>
        </div>
        <div className="flex items-center ">
          <div className="bg-xgreen w-[14px] h-[14px] rounded-full mr-[10px]" />
          <p className="p2-text ">Active</p>
        </div>
      </div>
      <div className="w-full text-center mt-10">
        <div
          className="radial-progress text-xred font-bold text-[24px] "
          style={{
            "--value": 100,
            "--size": "180px",
            "--thickness": "16px",
          }}
          role="progressbar"
        >
          <p className="h2-text">{doc?.DiffTotalDays ?? "--"}</p>
          <p className="p2-text ">day/s ago</p>
        </div>
      </div>
    </div>
  );
};

const Attachments = ({ doc }) => {
  const docItem = doc?.attachmentList;

  return (
    <div>
      <p className="h2-text">Attachments</p>
      <p className="p2-text ">Total {docItem?.length} files</p>

      {docItem?.length > 0 ? (
        docItem?.map((x) => (
          <div className="flex items-center mt-4">
            <img
              src={x?.FileExtention == ".pdf" ? images.pdf : images.file}
              alt="save"
              className="w-[32px] h-[32px] mr-[14px]"
            />
            <div>
              <p className="text-primary5 text-[16px] font-medium">
                {x?.FileName} ({x?.FileExtention})
              </p>
              <p className="text-primary4 text-[14px] mt-[1px] ">
                {bytesToMB(x?.Size)} MB
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="mt-4">No Doc attached</p>
      )}

      {/* <div className="flex items-center mt-4">
        <img
          src={images.jpg}
          alt="save"
          className="w-[32px] h-[32px] mr-[14px]"
        />
        <div>
          <p className="text-primary5 text-[16px] font-medium">
            Insurance Card
          </p>
          <p className="text-primary4 text-[14px] mt-[1px] ">1.4 MB</p>
        </div>
      </div> */}

      <section className="flex flex-wrap justify-between border-dashed border-blue-300 bg-blue-50 rounded-lg border-2  py-[17px] px-[25px] mt-[100px] ">
        <div className="flex flex-wrap">
          <img src={images.uploadFile} alt="save" className="mr-[16px]" />
          <div>
            <p className="font-medium text-[16px] text-primary2 ">
              Upload more files
            </p>
            <p className=" text-[16px] text-gray3 ">
              You are currently using basic plan
            </p>
          </div>
        </div>
        <button
          onClick={() =>
            document.getElementById("upgrade_plan_modal").showModal()
          }
          className="rounded-[6px] w-[115px] text-white bg-xgreen border-none  font-sans font-medium text-[12px] py-[10px] px-[16px]"
        >
          Upgrade Now
        </button>
      </section>
    </div>
  );
};

const MyTasks = ({ doc, sharer }) => {
  const [TaskData, setTaskData] = useState(null);

  useEffect(() => {
    doc?.Id && getTask();
  }, []);

  const getTask = useCallback(() => {
    TaskServices.GetTasksByDocId(doc?.Id)
      .then((resp) => {
        if (resp.status == 200) {
          setTaskData(resp.data.result);
        }
      })
      .catch((err) => {});
  });

  const updateTaskIsCompleted = (taskId, isCompleted) => {
    TaskServices.updateTaskIsCompleted(taskId, isCompleted)
      .then((resp) => {
        if (resp.status == 200) {
          toast.success("Task Updated Successfully!");
          getTask();
          // setTaskData(resp.data.result);
        }
      })
      .catch((err) => {});
  };

  return (
    <div>
      <CreateTaskModel
        doc={doc}
        getTask={getTask}
        TaskData={TaskData}
        sharer={sharer}
      />
      <div className="flex justify-between items-center ">
        <div>
          <p className="h2-text">My Tasks</p>
          <p className="p2-text ">Total 05 tasks</p>
        </div>
        <button
          onClick={() =>
            document.getElementById("create_task_modal").showModal()
          }
          className="rounded-[6px] w-[96px] text-blue1 bg-blue4 border-none  font-sans font-bold text-[12px] py-[10px] "
        >
          Create Task
        </button>
      </div>

      {TaskData &&
        TaskData?.map((task, i) => (
          <div key={i} className="flex justify-between items-center mt-[25px]">
            <div
              className={`flex flex-col px-[25px] rounded border-l-4 ${
                task?.IsCompleted ? "border-xgreen" : "border-xred"
              } `}
            >
              <p className="text-primary5 text-[16px] font-medium ">
                {task.TaskName || "--"}
              </p>
              <div className="flex gap-x-[20px]">
                <p
                  className={`${
                    task?.IsCompleted ? "text-xgreen" : "text-xred"
                  } text-[13px] font-medium`}
                >
                  {task?.IsCompleted ? "Completed" : "Overdue"}
                </p>

                <p className=" text-[13px] font-medium ">
                  {task?.IsDocCreator && "Myself"}
                </p>
              </div>
            </div>
            <input
              type="checkbox"
              onChange={(e) =>
                updateTaskIsCompleted(task?.Id, e.target.checked)
              }
              class="checkbox checkbox-info checkbox-md"
            />
          </div>
        ))}

      {/* <div className="flex justify-between items-center mt-4">
        <div className="flex flex-col border-l-4 border-xgreen px-[25px] rounded">
          <p className="text-primary4 text-[16px] font-medium ">
            Renew or Adjusting Coverage
          </p>
          <div className="flex gap-x-[20px]">
            <p className="text-xgreen text-[13px] font-medium ">Completed</p>
            <p className=" text-[13px] font-medium ">Myself</p>
          </div>
        </div>
     
        <input
          type="checkbox"
          checked="checked"
          class="checkbox checkbox-info checkbox-md"
        />
      </div> */}

      {/* <div className="flex justify-between items-center mt-[25px]">
        <div className="flex flex-col border-l-4 border-xred px-[25px] rounded">
          <p className="text-primary5 text-[16px] font-medium ">
            Update Personal Information
          </p>
          <div className="flex gap-x-[20px]">
            <p className="text-xred text-[13px] font-medium ">Overdue</p>
            <p className=" text-[13px] font-medium ">Myself</p>
          </div>
        </div>
        <input type="checkbox" class="checkbox checkbox-info checkbox-md" />
      </div>

      <div className="flex justify-between items-center mt-[25px]">
        <div className="flex flex-col border-l-4 border-xred px-[25px] rounded ">
          <p className="text-primary5 text-[16px] font-medium ">
            Assessment for Healthcare Needs
          </p>
          <div className="flex gap-x-[20px]">
            <p className="text-xred text-[13px] font-medium ">Overdue</p>
            <p className=" text-[13px] font-medium ">Myself</p>
          </div>
        </div>
        <input type="checkbox" class="checkbox checkbox-info checkbox-md" />
      </div> */}
    </div>
  );
};

const Sharers = ({ sharer }) => {
  return (
    <div>
      <p className="h2-text">Sharers</p>
      {/* <p className="p2-text ">Total 03 people</p> */}

      <div className=" w-full mt-4">
        <p className="p2-text mb-2">Invite a sharer</p>
        <select
          className="x-doc-input"
          // value={""}
          //  onChange={handleChange}
        >
          {sharer && sharer.length == 0 && (
            <option disabled>No Sharers to show</option>
          )}

          {sharer &&
            sharer?.map((item) => (
              <option value={item?.Id}>{item?.Name}</option>
            ))}
        </select>
      </div>

      {/* <p className="p2-text mb-2 mt-6">Document shared with</p>
      <div className="flex flex-wrap gap-x-2 gap-y-3">
        <div className={cs.sharer_badge}>
          <img
            src={images.pdf}
            alt="save"
            className=" w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
          />
          <p>Mahreen Akhtar</p>
          <img
            src={images.cross_icon}
            alt="save"
            className=" w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
          />
        </div>
        <div className={cs.sharer_badge}>
          <img
            src={images.pdf}
            alt="save"
            className=" w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
          />
          <p>Ali Ahmed</p>
          <img
            src={images.cross_icon}
            alt="save"
            className=" w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
          />
        </div>
        <div className={cs.sharer_badge}>
          <img
            src={images.pdf}
            alt="save"
            className=" w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
          />
          <p>Salman Khalid</p>
          <img
            src={images.cross_icon}
            alt="save"
            className=" w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
          />
        </div>
      </div> */}
    </div>
  );
};

const UpdateExpirationModel = () => {
  const [HaveDate, setHaveDate] = useState(false);

  return (
    <dialog id="update_expiration_modal" className="modal">
      <div className="modal-box  max-w-xl bg-white p-[60px] ">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <section>
          <p className="text-[26px] font-roboto font-bold text-gray5 ">
            Update expiration details
          </p>
          <p className="text-[16px] font-roboto mt-2 text-gray3">
            Add new expiration date after successful renewal to move the
            document to Active status.
          </p>

          <div className="mt-[32px] ">
            <LabelDoc id="document_name" label="Expiration Date" />
            <input
              type="date"
              // {...register("username")}
              id="document_name"
              className="x-doc-input"
              placeholder=""
            />
            {/* <Error errorName={errors.username} /> */}
          </div>

          <div className="flex items-start  mt-[32px]">
            <div className="flex items-center h-5">
              <input
                id="isAgree"
                // {...register("isAgree")}
                onChange={(e) => setHaveDate(e.target.checked)}
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
              />
            </div>

            <label
              htmlFor="isAgree"
              className="ms-2 text-sm font-medium text-gray3 dark:text-gray-300"
            >
              I have issue date and validity period.
            </label>
          </div>

          {HaveDate && (
            <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px]  mt-[32px] ">
              <div className=" w-full">
                <LabelDoc id="document_name" label="Issue Date" />
                <input
                  type="date"
                  // {...register("username")}
                  id="document_name"
                  className="x-doc-input"
                  placeholder=""
                />
              </div>
              <div className=" w-full">
                <LabelDoc id="document_name" label="Valid for" />
                <select
                  className="x-doc-input"
                  // value={""}
                  //  onChange={handleChange}
                >
                  <option value="">Select Duration</option>
                  <option value="My Docs">02 Months</option>
                  <option value="Health">04 Months</option>
                  <option value="Medical">06 Months</option>
                </select>
              </div>
            </div>
          )}

          <div className="mt-[60px] " />
          <LabelDoc id="document_name" label="Update Document" />
          <section className="flex flex-wrap justify-between border-dashed border-blue-300 bg-blue-50 rounded-lg border-2  py-[17px] px-[25px] ">
            <div className="flex flex-row ">
              <img src={images.uploadFile} alt="save" className="mr-[16px]" />
              <div>
                <p className="font-medium font-roboto  text-[16px] text-primary2 ">
                  Quick file uploader
                </p>
                <p className=" text-[16px] font-roboto text-gray3 ">
                  Drag & Drop or choose up to 2 files (max 03 MB size) from your
                  computer
                </p>
              </div>
            </div>
          </section>

          <div className="mt-[78px] text-center">
            <button className="rounded-[6px] h-[42px] py-[0] w-[160px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] ">
              Mark as Renewed
            </button>
          </div>
        </section>
      </div>
    </dialog>
  );
};

const CreateTaskModel = ({ doc, getTask, TaskData, sharer }) => {
  const {
    register,
    handleSubmit,
    errors,
    isValid,
    CreateTaskSubmitHandler,
    loading,
    reset,
    setValue,
  } = useTaskSubmit(doc?.Id, getTask, TaskData);

  return (
    <dialog id="create_task_modal" className="modal">
      <div className="modal-box  max-w-xl bg-white p-[55px] ">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <section>
          <form onSubmit={handleSubmit(CreateTaskSubmitHandler)}>
            <p className="text-[26px] font-roboto font-bold text-gray5 ">
              Create new task
            </p>
            <p className="text-[16px] font-roboto mt-2 text-gray3">
              Work together with friends and mates to get stuff done.
            </p>

            <div className="mt-[32px] ">
              <LabelDoc id="document_name" label="Title" />
              <input
                type="text"
                {...register("TaskName")}
                id="document_name"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.TaskName} />
            </div>

            <div className="mt-[32px] ">
              <LabelDoc id="document_name" label="Description (Optional)" />
              <input
                type="text"
                {...register("Description")}
                id="document_name"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.Description} />
            </div>

            <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px]  mt-[54px] ">
              <div className=" w-full">
                <LabelDoc id="AssignTo" label="Select Assignee" />
                <select
                  {...register("AssignTo")}
                  id="AssignTo"
                  className="x-doc-input"
                  defaultValue={null}
                  // value={""}
                  //  onChange={handleChange}
                >
                  {sharer && sharer.length == 0 && (
                    <option disabled>No Sharers to show</option>
                  )}

                  {sharer &&
                    sharer?.map((item) => (
                      <option value={item?.Id}>{item?.Name}</option>
                    ))}
                </select>
              </div>
              <div className=" w-full">
                <LabelDoc id="document_name" label="Add Due Date" />
                <input
                  type="date"
                  {...register("DueDate")}
                  id="document_name"
                  className="x-doc-input"
                  placeholder=""
                />
                <Error errorName={errors.DueDate} />
              </div>
            </div>

            <div className="mt-[78px] text-center">
              <button
                type="submit"
                className="rounded-[6px] h-[42px] py-[0] w-[160px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] "
              >
                Create Task
              </button>
            </div>
          </form>
        </section>
      </div>
    </dialog>
  );
};

const ViewDocument = () => {
  const location = useLocation();
  const doc = location.state || {};

  const [docData, setDocData] = useState([]);

  useEffect(() => {
    console.log("-->>>", doc.Id);
    // doc &&
    //   doc.Id &&
    //   doc.Id !== undefined &&
    //   doc.Id !== "undefined" &&
    doc && doc.Id && getDocumentDataById();
  }, []);

  const [sharer, setSharer] = useState(null);

  useEffect(() => {
    getSharerByUserDDL();
  }, []);

  const getSharerByUserDDL = useCallback(() => {
    DDLServices.GetSharerByUser()
      .then((resp) => {
        if (resp.status == 200) {
          setSharer(resp.data.result);
        }
      })
      .catch((err) => {});
  });

  const getDocumentDataById = useCallback(() => {
    DocumentServices.GetDocumentById(doc.Id)
      .then((resp) => {
        if (resp.status == 200) {
          setDocData(resp.data.result);
        }
      })
      .catch((err) => {
        // toast.error("Failed");
      });
  });

  return (
    <>
      <NavBar />
      <UpdateExpirationModel />

      <div className="max-w-screen-xl  mx-auto py-12">
        <div className="h-full bg-gray1 ">
          <div className="mb-5">
            <div className="pl-7 sm:pl-0">
              <H2 className="font-medium">{docData?.DocName}</H2>

              {/* //NOTE -  breadcrumbs */}
              <div className="text-sm breadcrumbs mb-8">
                <ul>
                  <li>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li>
                    <p className="text-gray5 font-medium">View Document</p>
                  </li>
                </ul>
              </div>
            </div>

            <InfoBoard
              doc={docData}
              getDocumentDataById={getDocumentDataById}
            />

            <div className="flex flex-row justify-between flex-wrap gap-5 mt-8 ">
              <div className={cs.card}>
                <DocumentDetails doc={docData} />
              </div>
              <div className={cs.card}>
                <ExpirationStatus doc={docData} />
              </div>
              <div className={cs.card}>
                <Attachments doc={docData} />
              </div>
              <div className={cs.card}>
                <MyTasks doc={docData} sharer={sharer} />
              </div>
              <div className={cs.card}>
                <Sharers doc={docData} sharer={sharer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDocument;
