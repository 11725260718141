import React, { useState, useEffect } from "react";
import LabelDoc from "../form/LabelDoc";
import useSharerSubmit from "../../hooks/useSharerSubmit";
import Error from "../form/Error";
import { useDispatch, useSelector } from "react-redux";
import { ClearSharer } from "../../redux/features/sharerSlice";

const InviteSharerModel = ({ refetchSharer }) => {
  const {
    register,
    handleSubmit,
    errors,
    loading,
    reset,
    setValue,
    CreateSharerSubmitHandler,
    UpdateSharerSubmitHandler,
    control,
  } = useSharerSubmit(refetchSharer);

  const { sharer } = useSelector((state) => state.sharer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sharer) {
      setValue("name", sharer?.Name);
      setValue("email", sharer?.Email);
    } else {
      reset();
    }

    // Set initial values
  }, [sharer]);

  return (
    <dialog id="invite_sharer_modal" className="modal">
      <div className="modal-box  max-w-xl bg-white p-[60px] ">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button
            onClick={() => {
              reset();
              dispatch(ClearSharer());
            }}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>
        </form>
        {/* onSubmit={handleSubmit(sharer ? UpdateSharerSubmitHandler : CreateSharerSubmitHandler)} */}
        <form onSubmit={handleSubmit(CreateSharerSubmitHandler)}>
          <section>
            <p className="text-[26px] font-roboto font-bold text-gray5 ">
              Invite a sharer
            </p>
            <p className="text-[16px] font-roboto mt-2 text-gray3">
              Add your mates to your team and start collaborating on keeping
              house in order.
            </p>

            <div className="mt-[32px] ">
              <LabelDoc id="document_name" label="Name" />
              <input
                type="text"
                {...register("name")}
                id="document_name"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.name} />
            </div>

            <div className="mt-[32px] ">
              <LabelDoc id="document_name" label="Email" />
              <input
                type="text"
                {...register("email")}
                id="document_name"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.email} />
            </div>

            <p className="mt-10 text-[18px] font-roboto font-bold text-blue1">
              Permissions
            </p>
            <div className="flex justify-between items-center mt-[25px]">
              <div className="flex flex-col ">
                <p className="text-primary5 text-[16px] font-medium font-roboto">
                  Can update documents
                </p>
                <div className="flex gap-x-[20px]">
                  <p className=" text-[13px] font-medium font-roboto">
                    Would be able to update details
                  </p>
                </div>
              </div>
              <input
                type="checkbox"
                class="toggle toggle-sm [--tglbg:white] bg-primary4 hover:bg-primary7 border-primary4"
                //   checked={reminderToggle}
                //   onChange={(e) => setreminderToggle(e.target.checked)}
              />
            </div>
            <div className="flex justify-between items-center mt-[25px]">
              <div className="flex flex-col ">
                <p className="text-primary5 text-[16px] font-medium font-roboto">
                  Can create tasks
                </p>
                <div className="flex gap-x-[20px]">
                  <p className=" text-[13px] font-medium font-roboto">
                    Would be able to create tasks and assign
                  </p>
                </div>
              </div>
              <input
                type="checkbox"
                class="toggle toggle-sm [--tglbg:white] bg-primary4 hover:bg-primary7 border-primary4"
                //   checked={reminderToggle}
                //   onChange={(e) => setreminderToggle(e.target.checked)}
              />
            </div>
            <div className="flex justify-between items-center mt-[25px]">
              <div className="flex flex-col ">
                <p className="text-primary5 text-[16px] font-medium font-roboto">
                  Can mark renewals
                </p>
                <div className="flex gap-x-[20px]">
                  <p className=" text-[13px] font-medium font-roboto">
                    Would be able to update document status
                  </p>
                </div>
              </div>
              <input
                type="checkbox"
                class="toggle toggle-sm [--tglbg:white] bg-primary4 hover:bg-primary7 border-primary4"
                //   checked={reminderToggle}
                //   onChange={(e) => setreminderToggle(e.target.checked)}
              />
            </div>

            <div className="mt-[78px] text-center">
              <button
                type="submit"
                className="rounded-[6px] h-[42px] py-[0] w-[160px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] "
              >
                {loading ? (
                  <span className="loading loading-spinner"></span>
                ) : sharer ? (
                  "Update Sharer"
                ) : (
                  "Send Invitation"
                )}
              </button>
            </div>
          </section>
        </form>
      </div>
    </dialog>
  );
};

export default InviteSharerModel;
