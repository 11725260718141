const formatCount = (num) => {
  let formattedNum;
  if (num < 10) {
    formattedNum = "0" + num;
  } else {
    formattedNum = num;
  }
  return formattedNum;
};

export default formatCount;
