import React from "react";
import cs from "./infoboard.module.scss";
import { images } from "../../../helpers/images";
import { H2 } from "../../../components/common/loader/TypoGraphy/TypoGraphy";
import Pricing from "../../../components/Pricing/Pricing.jsx";
import PricePlanModel from "../../../components/PricePlanModal/PricePlanModel.jsx";
import formatCount from "../../../helpers/formatCount.js";

const InfoBoard = ({ ExpiredThisMonth, ExpiringThisMonth }) => {
  return (
    <div className={cs.info_board}>
      <PricePlanModel />
      <div className={`${cs.info_board_left} flex flex-row flex-wrap`}>
        {/* <div
          className={`${cs.imgDiv} w-[160px] h-[160px] flex flex-col justify-center items-center`}
        >
          <img
            src={images.ImgPlaceholder}
            alt="img"
            className="w-[56px] h-[56px]"
          />
          <p className="p2-text" style={{ color: "#3699FF" }}>
            Add your Photo
          </p>
        </div> */}
        <div className="px-[30px]">
          <div>
            <p className="h2-text">Calendar</p>
            <p className="p2-text mt-1">
              Details about all your documents along with their current status
              in calendar view.
            </p>
          </div>
          <div className="flex flex-wrap justify-center md:justify-start mt-[20px] gap-[20px]">
            <div className="w-[100%] md:w-[170px] h-[70px] px-[15px] py-1 border-2 border-dashed border-primary6 rounded-lg ">
              <H2 className="text-xred">{formatCount(ExpiredThisMonth)}</H2>
              <p className="p2-text ">Expired this month</p>
            </div>
            <div className="w-[100%] md:w-[170px] h-[70px] px-[15px] py-1 border-2 border-dashed border-primary6 rounded-lg ">
              <H2 className="text-xyellow">{formatCount(ExpiringThisMonth)}</H2>
              <p className="p2-text ">Expiring this month</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${cs.info_board_right} flex flex-col items-center justify-center gap-2 `}
      >
        <img src={images.vector1} alt="img" />
        <p className="text-[16px] font-[700] text-primary5 font-sans">
          Upgrade to Pro
          <br />
          Track Limitless Documents
        </p>
        <button
          onClick={() =>
            document.getElementById("upgrade_plan_modal").showModal()
          }
          className="rounded-[6px] w-[115px] text-white bg-blue1 border-none  font-sans font-medium text-[12px] py-[10px] px-[16px]"
        >
          Upgrade Now
        </button>
      </div>
    </div>
  );
};

export default InfoBoard;
