import { client } from "../interfaces/api-urls";
import { getRequest } from "../interfaces/axios-methods";

async function GetDashboardCounts() {
  try {
    const response = await getRequest(client.GET_DASHBOARD_COUNTS);
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

async function GetExpiredDocDataTable() {
  try {
    const response = await getRequest(client.GET_EXPIRED_DOC_DATA_TABLE);
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

async function GetExpiringDocDataTable() {
  try {
    const response = await getRequest(client.GET_EXPIRING_DOC_DATA_TABLE);
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

async function GetActiveDocDataTable() {
  try {
    const response = await getRequest(client.GET_ACTIVE_DOC_DATA_TABLE);
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

export {
  GetDashboardCounts,
  GetExpiredDocDataTable,
  GetExpiringDocDataTable,
  GetActiveDocDataTable,
};
