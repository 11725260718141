import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createFolderValidation,
  signUpValidation,
} from "../helpers/validations";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AuthServices from "../services/api/auth.service";
import FolderServices from "../services/api/folder.service";

const useFolder = (folderColor, getFoldersData) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(createFolderValidation),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  // Updating values after component mounts or some event

  const CreateFolderSubmitHandler = (data) => {
    const payload = {
      Name: data.name,
      color: folderColor,
      UserId: null,
    };
    setLoading(true);
    FolderServices.createFolder(payload)
      .then((resp) => {
        setLoading(false);
        if (resp.status == 200) {
          toast.success("Folder Created Successfully!");

          reset();
          document.getElementById("create_folder_modal").close();
          getFoldersData();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Failed");
        document.getElementById("create_folder_modal").close();
      });
  };

  const UpdateFolderSubmitHandler = (data) => {
    const payload = {
      Name: data.name,
      color: folderColor,
      UserId: null,
      Id: data.Id,
    };
    setLoading(true);
    FolderServices.updateFolder(payload)
      .then((resp) => {
        setLoading(false);
        if (resp.status == 200) {
          toast.success("Folder Updated Successfully!");

          reset();
          document.getElementById("create_folder_modal").close();
          getFoldersData();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Failed");
        document.getElementById("create_folder_modal").close();
      });
  };

  return {
    register,
    handleSubmit,
    errors,
    CreateFolderSubmitHandler,
    UpdateFolderSubmitHandler,
    loading,
    reset,
    setValue,
  };
};

export default useFolder;
