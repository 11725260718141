import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { createDocumentValidation } from "../helpers/validations";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import FolderServices from "../services/api/folder.service";
import DocumentServices from "../services/api/document.service";
import { client } from "../services/interfaces/api-urls";
import { store } from "../redux/store";
import Swal from "sweetalert2";

const useDocumentSubmit = (uploadedFiles, reminderToggle, reminders) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(createDocumentValidation),
    mode: "all",
    reValidateMode: "onBlur",
  });
  // Updating values after component mounts or some event

  const CreateDocumentSubmitHandler = (data) => {
    let defaultReminders = [
      { RemindValue: 1, name: "02 Weeks", selected: true, disabled: true },
      { RemindValue: 2, name: "04 Weeks", selected: true, disabled: true },
      { RemindValue: 3, name: "02 Months", selected: true, disabled: true },
      { RemindValue: 4, name: "06 Months", selected: true, disabled: true },
    ];

    // const remindOn = data?.RemindMe
    //   ? JSON.stringify(defaultReminders.concat(reminders))
    //   : JSON.stringify(defaultReminders);
    const remindOn = data?.RemindMe
      ? JSON.stringify(defaultReminders.concat(reminders))
      : JSON.stringify(defaultReminders);
    // const remindOn =
    //   '[{"RemindValue":1,"name":"02 Weeks","selected":true,"disabled":true},{"RemindValue":2,"name":"04 Weeks","selected":true,"disabled":true},{"RemindValue":3,"name":"02 Months","selected":true,"disabled":true},{"RemindValue":4,"name":"06 Months","selected":true,"disabled":true},{"CustomRemindValue":1,"CustomRemindPeriod":"Weeks","RemindValue":5,"selected":true},{"CustomRemindValue":3,"CustomRemindPeriod":"Months","RemindValue":5,"selected":true},{"CustomRemindValue":7,"CustomRemindPeriod":"Weeks","RemindValue":5,"selected":true}]';

    data["ExpiryDate"] = dayjs(data?.ExpiryDate).format("YYYY-MM-DD");
    data["IssueDate"] = dayjs(data?.IssueDate).format("YYYY-MM-DD");

    const formData = new FormData();

    formData.append("DocName", data?.DocName);
    formData.append("ExpiryDate", data?.ExpiryDate);
    formData.append("IssueDate", data.IssueDate);
    formData.append("ValidFor", data?.ValidFor || "");
    formData.append("DocTypeId", data?.DocTypeId);
    formData.append("FolderId", data?.FolderId);
    formData.append("DocOwnerName", data?.DocOwnerName || null);
    formData.append("DocNumber", data?.DocNumber || "");
    formData.append("CountryId", data?.CountryId || "");
    formData.append("StateId", data?.StateId || "");
    formData.append("remindOn", remindOn);
    formData.append("sendOn", []);
    formData.append("ValidForPeriod", data?.ValidForPeriod || "");
    formData.append("RemindMe", data?.RemindMe);

    if (uploadedFiles) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append("file", uploadedFiles[i]);
      }
    }

    // console.log("✅", formData, remindOn);
    // return;
    const BaseURL = process.env.REACT_APP_BASEURL;
    // return;
    // Your Bearer token
    // const token =
    //   "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJjb3ZpeDQ0NzYzQHNoYWZseW4uY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI3ZWRkMTllMS0zYzkyLTQ0YWUtYjQ5NS02NDBjMDY3MDU2MjgiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiTXIgQ292aXgiLCJqdGkiOiI4NDcxMDY4Zi0xOGM3LTQ0ODgtOTIyOS1iMzc2ZWQ5NDU2NDkiLCJleHAiOjE3MTczMDY4MDIsImlzcyI6Imh0dHBzOi8vYXBpcy54cGlyZWUuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBwcy54cGlyZWUuY29tLyJ9.5yiF9T8J6gSv7-5HW3LE5iF8P9HKLzxdyfsTJqrRqYc";
    setLoading(true);
    fetch(`${BaseURL}/${client.CREATE_DOCUMENT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store.getState()?.auth?.user?.accessToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        console.log({ data });
        if (data == 1) {
          Swal.fire({
            title: "Document added successfully!",
            text: "Start assigning tasks, add notes or share documents with your friends.",
            icon: "success",
          });
          navigate("/documents");
          return;
        }

        if (data == -3) {
          Swal.fire({
            title: "Oops!",
            text: "Document Name Already Exists",
            icon: "info",
          });
          // navigate("/documents");
          return;
        }

        if (data == -4) {
          Swal.fire({
            title: "Oops!",
            text: "File size exceeds upload limit",
            icon: "info",
          });
          // navigate("/documents");
          return;
        }

        if (data == -5) {
          Swal.fire({
            title: "Upgrade Plan!",
            text: "You are current using Xpiree basic plan. Upgrade Plan to add avail more features",
            icon: "info",
          });
          // navigate("/documents");
          return;
        }

        // navigate("/documents");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to add Document!");
        console.error("Error:", error);
      });
  };

  const UpdateDocumentSubmitHandler = (data) => {
    let defaultReminders = [
      { RemindValue: 1, name: "02 Weeks", selected: true, disabled: true },
      { RemindValue: 2, name: "04 Weeks", selected: true, disabled: true },
      { RemindValue: 3, name: "02 Months", selected: true, disabled: true },
      { RemindValue: 4, name: "06 Months", selected: true, disabled: true },
    ];

    // const remindOn = data?.RemindMe
    //   ? JSON.stringify(defaultReminders.concat(reminders))
    //   : JSON.stringify(defaultReminders);
    const remindOn = data?.RemindMe
      ? JSON.stringify(defaultReminders.concat(reminders))
      : JSON.stringify(defaultReminders);
    // const remindOn =
    //   '[{"RemindValue":1,"name":"02 Weeks","selected":true,"disabled":true},{"RemindValue":2,"name":"04 Weeks","selected":true,"disabled":true},{"RemindValue":3,"name":"02 Months","selected":true,"disabled":true},{"RemindValue":4,"name":"06 Months","selected":true,"disabled":true},{"CustomRemindValue":1,"CustomRemindPeriod":"Weeks","RemindValue":5,"selected":true},{"CustomRemindValue":3,"CustomRemindPeriod":"Months","RemindValue":5,"selected":true},{"CustomRemindValue":7,"CustomRemindPeriod":"Weeks","RemindValue":5,"selected":true}]';

    data["ExpiryDate"] = dayjs(data?.ExpiryDate).format("YYYY-MM-DD");
    data["IssueDate"] = dayjs(data?.IssueDate).format("YYYY-MM-DD");

    const formData = new FormData();
    formData.append("Id", data?.Id);
    formData.append("DocName", data?.DocName);
    formData.append("ExpiryDate", data?.ExpiryDate);
    formData.append("IssueDate", data.IssueDate);
    formData.append("ValidFor", data?.ValidFor || "");
    formData.append("DocTypeId", data?.DocTypeId);
    formData.append("FolderId", data?.FolderId);
    formData.append("DocOwnerName", data?.DocOwnerName || null);
    formData.append("DocNumber", data?.DocNumber || "");
    formData.append("CountryId", data?.CountryId || "");
    formData.append("StateId", data?.StateId || "");
    formData.append("remindOn", remindOn);
    formData.append("sendOn", []);
    formData.append("ValidForPeriod", data?.ValidForPeriod || "");
    formData.append("RemindMe", data?.RemindMe);

    if (uploadedFiles) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append("file", uploadedFiles[i]);
      }
    }

    // console.log("✅", formData, remindOn);
    // return;
    const BaseURL = process.env.REACT_APP_BASEURL;
    // return;
    // Your Bearer token
    // const token =
    //   "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJjb3ZpeDQ0NzYzQHNoYWZseW4uY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI3ZWRkMTllMS0zYzkyLTQ0YWUtYjQ5NS02NDBjMDY3MDU2MjgiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiTXIgQ292aXgiLCJqdGkiOiI4NDcxMDY4Zi0xOGM3LTQ0ODgtOTIyOS1iMzc2ZWQ5NDU2NDkiLCJleHAiOjE3MTczMDY4MDIsImlzcyI6Imh0dHBzOi8vYXBpcy54cGlyZWUuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBwcy54cGlyZWUuY29tLyJ9.5yiF9T8J6gSv7-5HW3LE5iF8P9HKLzxdyfsTJqrRqYc";
    setLoading(true);
    fetch(`${BaseURL}/${client.UPDATE_DOCUMENT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store.getState()?.auth?.user?.accessToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        console.log({ data });
        if (data == 1) {
          Swal.fire({
            title: "Document added successfully!",
            text: "Start assigning tasks, add notes or share documents with your friends.",
            icon: "success",
          });
          navigate("/documents");
          return;
        }

        if (data == -3) {
          Swal.fire({
            title: "Oops!",
            text: "Document Name Already Exists",
            icon: "info",
          });
          // navigate("/documents");
          return;
        }

        if (data == -4) {
          Swal.fire({
            title: "Oops!",
            text: "File size exceeds upload limit",
            icon: "info",
          });
          // navigate("/documents");
          return;
        }

        if (data == -5) {
          Swal.fire({
            title: "Upgrade Plan!",
            text: "You are current using Xpiree basic plan. Upgrade Plan to add avail more features",
            icon: "info",
          });
          // navigate("/documents");
          return;
        }

        // navigate("/documents");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to add Document!");
        console.error("Error:", error);
      });
  };

  return {
    register,
    handleSubmit,
    errors,
    watch,
    isValid,
    CreateDocumentSubmitHandler,
    UpdateDocumentSubmitHandler,
    loading,
    reset,
    setValue,
    control,
  };
};

export default useDocumentSubmit;
