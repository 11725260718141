import React from "react";

const H1 = ({ children, className }) => {
  return (
    <p className={`font-semibold text-[36px] text-primary1 ${className}`}>
      {children}
    </p>
  );
};

const H2 = ({ children, className }) => {
  return (
    <p className={` ${className} font-semibold text-[20px] text-primary5`}>
      {children}
    </p>
  );
};

const H3 = ({ children, className }) => {
  return (
    <p className={`font-semibold text-[16x] text-primary5 ${className}`}>
      {children}
    </p>
  );
};

const H4 = ({ children, className }) => {
  return (
    <p className={`font-semibold text-[14x] text-primary5 ${className}`}>
      {children}
    </p>
  );
};

const Text1 = ({ children, className }) => {
  return (
    <p className={`text-[16px] text-primary3 leading-6 ${className}`}>
      {children}
    </p>
  );
};

const Text2 = ({ children, className }) => {
  return (
    <p className={`text-[14px] text-primary3 leading-6 ${className}`}>
      {children}
    </p>
  );
};

const Text3 = ({ children, className }) => {
  return (
    <p
      className={`font-medium text-[12px] text-primary3 leading-6  ${className}`}
    >
      {children}
    </p>
  );
};

export { Text1, Text2, Text3, H1, H2, H3, H4 };
