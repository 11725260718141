import { DDL, client } from "../interfaces/api-urls";
import { getRequest, postRequest } from "../interfaces/axios-methods";

const DDLServices = {
  GetSharerByUser: async () => {
    return getRequest(DDL.getSharerByUserDDL);
  },
  GetUserFolders: async () => {
    return getRequest(DDL.getUserFoldersDLL);
  },
  GetCoutries: async () => {
    return getRequest(DDL.getCountriesDDL);
  },
  GetStats: async (id) => {
    return getRequest(DDL.getStatesDDL + "?countryId=" + id);
  },
  GetDocType: async () => {
    return getRequest(DDL.getDocTypeDDL);
  },
};

export default DDLServices;
