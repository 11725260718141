import React, { useState } from "react";
import dayjs from "dayjs";
import Badge from "../../components/common/loader/badge";
import { images } from "../../helpers/images";
import { DOCUMENT_STATUS } from "../../helpers/constants";
import Swal from "sweetalert2";
import DocumentServices from "../../services/api/document.service";
import { toast } from "react-toastify";
import TaskServices from "../../services/api/task.service";

const EditDeleteButton = ({
  doc,
  getGetAssignedToMeTaskData,
  completedTasks,
  setCompletedTasks,
  index,
}) => {
  const confirmDelete = (document) => {
    Swal.fire({
      title: "Are you sure?",
      text: "The document will be removed permanently from the account.",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `Don't delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        DocumentServices.deleteDocument(document.Id)
          .then((resp) => {
            if (resp.status == 200) {
              toast.success("Document Deleted!", "", "success");
              getGetAssignedToMeTaskData();
            }
          })
          .catch((err) => {
            toast.error("Failed");
          });
      }
    });
  };

  const updateTaskIsCompleted = (taskId, isCompleted) => {
    TaskServices.updateTaskIsCompleted(taskId, isCompleted)
      .then((resp) => {
        if (resp.status == 200) {
          toast.success("Task Updated Successfully!");
          // getGetAssignedToMeTaskData();
          // getTask();
          // setTaskData(resp.data.result);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="flex items-center">
      <div class="flex items-center ">
        <input
          id="default-checkbox"
          type="checkbox"
          // disabled={completedTasks.includes(index)}
          onChange={(e) => {
            console.log(e.target.checked, doc.Id);
            if (e.target.checked) {
              let temp = [...completedTasks];
              temp.push(doc.Id);
              setCompletedTasks(temp);
            } else {
              let temp = [...completedTasks];
              const index = temp.indexOf(doc.Id);
              if (index > -1) {
                temp.splice(index, 1);
              }
              setCompletedTasks(temp);
            }
            updateTaskIsCompleted(doc.Id, e.target.checked);
          }}
          value=""
          class="w-4 h-4 rounded-full text-blue-600 bg-gray-100 border-gray-300  focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          for="default-checkbox"
          class="ms-4 text-sm font-medium text-gray-900 dark:text-gray-300"
        ></label>
      </div>
      {/* <img src={images.edit_icon} alt="edit" /> */}
      <img
        src={images.delete_icon}
        alt="delete"
        className="  cursor-pointer"
        // onClick={() => confirmDelete(doc)}
      />
    </div>
  );
};

const ProfileStack = () => {
  return (
    <div class="flex -space-x-4 rtl:space-x-reverse">
      <img
        class="relative bg-primary6 border-2 border-white  inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full dark:bg-gray-600"
        src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
        alt=""
      />
      <div class="relative bg-primary6 border-2 border-white inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-500 rounded-full dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">JL</span>
      </div>

      <div class="relative bg-primary6 border-2 border-white  inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full dark:bg-gray-600">
        <span class="font-medium text-xs text-gray-600 dark:text-gray-300">
          {" "}
          +99
        </span>
      </div>
    </div>
  );
};

const TaskTable = ({ taskData, getGetAssignedToMeTaskData }) => {
  const [completedTasks, setCompletedTasks] = useState([]);

  return (
    <div>
      <div className="overflow-x-auto mt-[20px]">
        <table className="table ">
          {/* head */}
          <thead>
            <tr className="bg-gray1">
              {/* <th></th> */}
              <th>TASK NAME</th>
              <th>DOCUMENT</th>
              <th>ASSIGNED TO</th>
              <th>DUE Date</th>

              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {taskData.length == 0 && (
              <tr>
                <td
                  style={{ textAlign: "center" }}
                  colSpan={5}
                  className="text-primary5 font-medium opacity-50"
                >
                  No Task Assigned
                </td>
              </tr>
            )}
            {/* row 1 */}
            {taskData &&
              taskData.map((doc, index) => (
                <tr
                  className={
                    completedTasks.includes(doc.Id) ? "completed_task" : ""
                  }
                >
                  <td className="text-primary5 font-medium">{doc?.TaskName}</td>
                  <td className="text-primary5 font-medium">{doc?.DocName}</td>
                  <td>{/* <ProfileStack /> */}-</td>
                  <td
                    className={
                      new Date(doc?.DueDate) < new Date()
                        ? "text-xred"
                        : "text-primary7"
                    }
                  >
                    {dayjs(doc?.DueDate).format("MMM D, YYYY")}
                  </td>

                  <td>
                    <EditDeleteButton
                      doc={doc}
                      getGetAssignedToMeTaskData={getGetAssignedToMeTaskData}
                      completedTasks={completedTasks}
                      setCompletedTasks={setCompletedTasks}
                      index={index}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskTable;
