import React from "react";

const Badge = ({ type, className }) => {
  let textColorClass = "";
  let bgColorClass = "";

  switch (type) {
    case "Expired":
      textColorClass = "text-xred";
      bgColorClass = "bg-red-100";
      break;
    case "Expiring":
      textColorClass = "text-xyellow";
      bgColorClass = "bg-yellow-100";
      break;
    case "Active":
      textColorClass = "text-xgreen";
      bgColorClass = "bg-green-100";
      break;
    default:
      textColorClass = "";
      bgColorClass = "";
  }

  return (
    <span
      className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded  ${textColorClass} ${bgColorClass} ${className}`}
    >
      {type}
    </span>
  );
};

export default Badge;
