import AddDocuments from "../pages/addDocuments/AddDocuments";
import Calendar from "../pages/calendar/calendar";
import ConfirmEmail from "../pages/confirmEmail/confirmEmail";
import Dashboard from "../pages/dashboard/Dashboard";
import Documents from "../pages/documents/Documents";
import Folders from "../pages/folders/folders";
import Login from "../pages/login/Login";
import SignUp from "../pages/signup/SignUp";
import TaskPage from "../pages/tasks/tasks";
import ViewDocument from "../pages/viewDocument/ViewDocument";

const PUBLIC_ROUTES = [
  {
    routeName: "Login",
    path: "/login",
    component: Login,
  },
  {
    routeName: "SignUp",
    path: "/signup",
    component: SignUp,
  },
  {
    routeName: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
  },
  {
    routeName: "Documents",
    path: "/documents",
    component: Documents,
  },
  {
    routeName: "addDocument",
    path: "client/addDocument",
    component: AddDocuments,
  },
  {
    routeName: "Calendar",
    path: "/calendar",
    component: Calendar,
  },
  {
    routeName: "Folders",
    path: "/folders",
    component: Folders,
  },
  {
    routeName: "ViewDocument",
    path: "client/viewDocument",
    component: ViewDocument,
  },
  {
    routeName: "Task",
    path: "/task",
    component: TaskPage,
  },
  {
    routeName: "ConfirmEmail",
    path: "client/ConfirmEmail/:UserId/:token",
    component: ConfirmEmail,
  },
];

export default PUBLIC_ROUTES;
