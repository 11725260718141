import React from "react";

const Error = ({ errorName }) => {
  return (
    <>
      {errorName && (
        <p className="text-red-500 text-[12px] mt-2">{errorName.message}</p>
      )}
    </>
  );
};

export default Error;
