const auth = {
  LOGIN_API_URL: "Account/signIn",
  SIGNUP_API_URL: "Account/signUp",
  FORGET_PASSWORD: "Account/forgetPassword",
  GET_USER_INFO: "Account/GetUserInfo",
  CONFIRM_EMAIL: "Account/ConfirmEmail",
};

const users = {
  GET_ALL_USERS: "users",
  UPDATE_USER_INFO: "Account/UpdateUserAccount",
};

const client = {
  GET_DASHBOARD_COUNTS: "Client/GetDashboardCounts",
  GET_EXPIRED_DOC_DATA_TABLE: "Client/GetExpiredDocDataTable",
  GET_EXPIRING_DOC_DATA_TABLE: "Client/GetExpiringDocDataTable",
  GET_ACTIVE_DOC_DATA_TABLE: "Client/GetActiveDocDataTable",

  //NOTE - FOLDERS
  GET_FOLDER_DATA_TABLE: "Client/getFolderDataTable",
  CREATE_FOLDER: "Client/addFolder",
  UPDATE_FOLDER: "Client/updateFolder",
  DELETE_FOLDER: "Client/deleteFolder",

  //NOTE - DOCUMENTS
  GET_DOCUMENT_DATA_TABLE: "Client/GetAllDocumentDataTable",
  GET_DOCUMENT_BY_ID: "Client/GetDocumentById",
  DELETE_DOCUMENT: "Client/deleteDocument",
  CREATE_DOCUMENT: "Client/addDocument",
  UPDATE_DOCUMENT: "Client/updateDocument",
  UPDATE_DOC_STATUS: "Client/updateUserDocStatus",

  //NOTE - TASKS
  getAssignedToMeTaskList: "Client/getAssignedToMeTaskList",
  getAssignedByMeTaskList: "Client/getAssignedByMeTaskList",
  getCompletedTaskList: "Client/getCompletedTaskList",

  getAssignToMeTaskByDocId: "Client/GetAssignToMeTaskByDocId",
  updateTaskIscompleted: "Client/updateTaskIscompleted",
  createTask: "Client/AddTask",

  //NOTE - SHARER
  GET_SHARER_DATA_TABLE: "Client/getSharerDataTable",
  ADD_SHARER: "Client/addSharer",
  DELETE_SHARER_INVITATION: "Client/deleteInvitation",
};

const DDL = {
  getSharerByUserDDL: "DDL/getSharerByUserDDL",
  getUserFoldersDLL: "DDL/getUserFoldersDLL",
  getCountriesDDL: "DDL/getCountryDDL",
  getStatesDDL: "DDL/getStateDDL",
  getDocTypeDDL: "DDL/getDocTypeDDL",
};

const profile = {
  ADD_STRIPE_CUSTOMER: "Stripe/AddStripeCustomer",
};

export { auth, users, client, DDL, profile };
