import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folder: null,
};

const folderSlice = createSlice({
  name: "folder",
  initialState: initialState,
  reducers: {
    // actions will come here
    SelectFolder: (state, action) => {
      state.folder = action.payload;
    },
    ClearFolder: (state) => {
      state.folder = null;
    },
  },
});

export const { SelectFolder, ClearFolder } = folderSlice.actions;
export default folderSlice.reducer;
