import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AuthServices from "../../services/api/auth.service";

const ConfirmEmail = () => {
  const { UserId, token } = useParams();
  // Decode token if necessary
  const decodedToken = decodeURIComponent(token);
  const navigate = useNavigate();
  const [Message, setMessage] = useState(null);

  useEffect(() => {
    emailConfirmation(UserId, decodedToken);
  }, []);

  const emailConfirmation = (UserId, decodedToken) => {
    
    if (UserId && decodedToken) {
      AuthServices.confirmEmail(UserId, decodedToken)
        .then((resp) => {
          if (resp.status == 200) {
            setMessage("Your email have confirmed successfully!");
            toast.success("Your email have confirmed successfully!");
          } else {
            // toast.error("Login Failed");
            toast.error(resp.title || "Something went wrong");
          }
        })
        .catch((err) => {
          if (err.response.data) {
            setMessage(err.response.data);
          }

          toast.info(err.response.data || "Failed");
          // toast.error("Invalid Credential");
        });
    } else {
      toast.info("Failed");
    }
    // toast.success("ForgetLogin Successful");
  };

  return (
    <div className="h-screen  flex flex-col justify-center items-center">
      <p className="h-text">Email Confirmation!</p>
      <p className="p-text mt-[12px] mb-3">{Message}</p>
      <button
        className={`btn font-medium text-white mt-[32px]  bg-blue1 w-[200px] `}
        onClick={() => navigate("/login")}
      >
        Proceed to Login
      </button>
    </div>
  );
};

export default ConfirmEmail;
