import React from "react";

const LabelDoc = ({ id, label }) => {
  return (
    <label
      htmlFor={id}
      className="block mb-2 text-sm font-bold text-[14px]  text-primary2 "
    >
      {label}
    </label>
  );
};

export default LabelDoc;
