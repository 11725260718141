import React from "react";
import Label from "../form/Label";
import Error from "../form/Error";
import usePaymentPlan from "../../hooks/usePaymentPlan";

const UpgradePlan = ({ setIsUpgradePlan }) => {
  const {
    register,
    handleSubmit,
    errors,
    loading,
    watch,
    upgradePlanSubmitHandler,
  } = usePaymentPlan();
  return (
    <div>
      <div className="w-100 flex justify-center items-center">
        <div className="w-[400px] bg-primary8 p-6 mx-auto max-w-md  rounded-box shadow-sm xl:p-8  border border-base-300 shadow-primary/10 border-primary/10">
          <form onSubmit={handleSubmit(upgradePlanSubmitHandler)}>
            {/* //NOTE - Name */}
            <div className=" ">
              <Label id="user_name" label="Name" />
              <input
                type="text"
                {...register("username")}
                id="user_name"
                className="x-input"
                placeholder="Leslie Alexandar"
              />
              <Error errorName={errors.username} />
            </div>

            {/* //NOTE - Email */}
            <div className="mt-[20px]">
              <Label id="user_email" label="Email" />
              <input
                type="email"
                {...register("email")}
                id="user_email"
                className="x-input"
                placeholder="example@xpiree.com"
              />
              <Error errorName={errors.email} />
            </div>

            <div className="mt-[20px] ">
              <Label id="cardNumber" label="Card Number" />
              <input
                type="text"
                {...register("cardNumber")}
                id="cardNumber"
                className="x-input"
                placeholder="Card Number"
              />
              <Error errorName={errors.cardNumber} />
            </div>

            <div className="mt-[20px] ">
              <Label id="cvc" label="CVC" />
              <input
                type="text"
                {...register("cvc")}
                id="cvc"
                className="x-input"
                placeholder="Enter CVC"
              />
              <Error errorName={errors.cvc} />
            </div>
            <div className="mt-[20px] ">
              <Label id="expirationMonth" label="Expiration Date" />
              <div className="flex gap-3">
                <input
                  type="number"
                  {...register("expirationMonth")}
                  id="expirationMonth"
                  className="x-input"
                  placeholder="Month"
                />
                <input
                  type="number"
                  {...register("expirationYear")}
                  id="expirationYear"
                  className="x-input"
                  placeholder="Year"
                />
              </div>
              <Error errorName={errors.expirationMonth} />
              <Error errorName={errors.expirationYear} />
            </div>

            <button
              type="submit"
              className={`btn-filled bg-blue1 font-medium text-white mt-[32px] `}
            >
              {loading ? (
                <span className="loading loading-spinner"></span>
              ) : (
                "Upgrade"
              )}
            </button>
            <button
              onClick={() => setIsUpgradePlan(false)}
              className={`btn-filled bg-white font-medium text-black mt-[10px] `}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
