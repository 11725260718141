import React, { useState, useEffect, useCallback } from "react";
import NavBar from "../../Layout/Navbar";
import InfoBoard from "./infoBoard/InfoBoard";
import { images } from "../../helpers/images";
import { H2 } from "../../components/common/loader/TypoGraphy/TypoGraphy";
import DocTable from "./DocTable";
import { useNavigate } from "react-router-dom";
import DocumentServices from "../../services/api/document.service";
import { toast } from "react-toastify";

const Documents = () => {
  const navigate = useNavigate();

  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDocumentData();
  }, []);

  const getDocumentData = useCallback(() => {
    setLoading(true);
    DocumentServices.GetDocuments()
      .then((resp) => {
        setLoading(false);

        if (resp.status == 200) {
          setDocumentData(
            resp.data.result.sort(
              (a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn)
            )
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Failed");
      });
  });

  return (
    <>
      <NavBar />
      {/* flex flex-wrap items-center justify-between */}
      <div className="max-w-screen-xl  mx-auto py-7">
        <div className="h-full bg-gray1">
          <InfoBoard documentData={documentData} />
          {documentData?.length !== 0 && (
            <div className=" bg-white my-12 rounded-[12px] flex flex-col p-0  md:p-[30px]">
              {/* <div className="flex justify-between ">
              <H2>My Documents</H2>
              <div className="flex ">
                <label
                  style={{ background: "white" }}
                  className="input input-bordered flex items-center gap-2 h-[34px]   "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="w-4 h-4 opacity-70"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <input
                    type="text"
                    className="w-[135px] text-[12px] "
  
                    placeholder="Search"
                  />
                </label>
               
              </div>
            </div> */}

              <DocTable
                documentData={documentData}
                getDocumentData={() => getDocumentData()}
              />
            </div>
          )}
          {!loading && documentData?.length == 0 && (
            <div className=" bg-white my-6 rounded-[12px] flex flex-col justify-center items-center py-[90px]">
              <img
                src={images.ImgPlaceholder2}
                alt="img"
                className="w-[175px] "
              />
              <p className="h2-text">Let’s Get Started</p>
              <p className="p2-text my-1">
                Start by adding a document to track expiration
              </p>
              <button
                onClick={() =>
                  navigate("/client/addDocument", { replace: true })
                }
                className="btn btn-wide text-white bg-blue1 border-none mt-[60px]"
              >
                Add New Document
              </button>
            </div>
          )}

          {loading && (
            <div className="bg-white h-100  my-12 rounded-[12px] flex flex-col justify-center items-center py-[250px]">
              <p className="p2-text my-1">Loading...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Documents;
