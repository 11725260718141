import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpValidation } from "../helpers/validations";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AuthServices from "../services/api/auth.service";

const useSignup = (setEmailVerification) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signUpValidation),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const SignUpSubmitHandler = (data) => {
    const payload = {
      name: data.username,
      email: data.email,
      password: data.password,
    };

    setLoading(true);

    AuthServices.signUpUser(payload)
      .then((resp) => {
        if (resp.status == 200 && resp.data) {
          if (resp.data == "1") {
            toast.success("Verification email sent");
            setEmailVerification(true);
          }
          if (resp.data == "-1") {
            toast.error("Email sending problem");
          }
          if (resp.data == "-3") {
            toast.info("This Email is already registered");
          }

          // reset();
          // navigate("/login");
        } else {
          toast.error("Sign Up Process Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  return {
    register,
    handleSubmit,
    errors,
    SignUpSubmitHandler,
    loading,
    watch,
  };
};

export default useSignup;
