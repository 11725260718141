import React, { useState } from "react";

const PasswordRequirements = ({ password }) => {
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const validatePassword = (password) => {
    setValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[@$!%*?&#]/.test(password),
    });
  };

  React.useEffect(() => {
    validatePassword(password);
  }, [password]);

  return (
    <div>
      {/* <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
        Password requirements:
      </h2> */}
      <ul className="mt-2 text-[12px] ml-2 max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
        <li
          className={`flex items-center ${
            validations.length ? "text-green-500" : "text-gray-500"
          }`}
        >
          <svg
            className="w-3.5 h-3.5 me-2 flex-shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
          </svg>
          At least 8 characters
        </li>
        <li
          className={`flex items-center ${
            validations.lowercase ? "text-green-500" : "text-gray-500"
          }`}
        >
          <svg
            className="w-3.5 h-3.5 me-2 flex-shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
          </svg>
          At least one lowercase character
        </li>
        <li
          className={`flex items-center ${
            validations.uppercase ? "text-green-500" : "text-gray-500"
          }`}
        >
          <svg
            className="w-3.5 h-3.5 me-2 flex-shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
          </svg>
          At least one uppercase character
        </li>
        <li
          className={`flex items-center ${
            validations.digit ? "text-green-500" : "text-gray-500"
          }`}
        >
          <svg
            className="w-3.5 h-3.5 me-2 flex-shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
          </svg>
          At least one digit
        </li>
        <li
          className={`flex items-center ${
            validations.specialChar ? "text-green-500" : "text-gray-500"
          }`}
        >
          <svg
            className="w-3.5 h-3.5 me-2 flex-shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
          </svg>
          At least one special character, e.g., ! @ # ?
        </li>
      </ul>
    </div>
  );
};

export default PasswordRequirements;
