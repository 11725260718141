const USER = {
  get_success: "Record fetched Successfully!",
  add_success: "User record added successfully",
  edit_success: "User record updated successfully",
  update_status_success: "User status updated successfully",
  password_reset_success: "Password updated successfully",
  login_success: "User Logged in Successfully",
};

export { USER };
