import React, { useState, useEffect } from "react";
import LabelDoc from "../form/LabelDoc";
import useFolder from "../../hooks/useFolder";
import Error from "../../components/form/Error";
import { useDispatch, useSelector } from "react-redux";
import { ClearFolder } from "../../redux/features/folderSlice";

const CreateFolder = ({ getFoldersData }) => {
  const [folderColor, setFolderColor] = useState("#5BA4CF");

  const { folder } = useSelector((state) => state.folder);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    errors,
    loading,
    reset,
    setValue,
    CreateFolderSubmitHandler,
    UpdateFolderSubmitHandler,
  } = useFolder(folderColor, getFoldersData);

  useEffect(() => {
    if (folder) {
      setValue("name", folder?.Name);
      setValue("Id", folder?.Id);
      setFolderColor(folder?.Color);
    } else {
      reset();
      setFolderColor("#5BA4CF");
    }

    // Set initial values
  }, [folder]);

  return (
    <dialog id="create_folder_modal" className="modal">
      <div className="modal-box  max-w-xl bg-white p-[60px] ">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button
            onClick={() => {
              reset();
              dispatch(ClearFolder());
            }}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>
        </form>
        <section>
          <p className="text-[26px] font-roboto font-bold text-gray5 ">
            {folder ? "Edit" : "Create"} a folder
          </p>
          <p className="text-[16px] font-roboto mt-2 text-gray3">
            Organize your documents in folders so nothing gets lost.
          </p>

          <form
            onSubmit={handleSubmit(
              folder ? UpdateFolderSubmitHandler : CreateFolderSubmitHandler
            )}
          >
            <div className="mt-[32px] ">
              <LabelDoc id="folder_name" label="Name" />
              <input
                type="text"
                {...register("name")}
                id="folder_name"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.name} />
            </div>

            <div className="flex flex-wrap gap-y-5 justify-between items-center mt-[48px] ">
              <LabelDoc id="folder_color" label="Label Color" />
              <div className="flex items-center gap-1">
                <div
                  onClick={() => setFolderColor("#EF7564")}
                  style={{ borderWidth: folderColor == "#EF7564" ? 2 : 0 }}
                  className=" border-slate-10 rounded-full"
                >
                  <div
                    className="w-[20px] h-[20px] rounded-[10px] cursor-pointer m-[5px]"
                    style={{ background: "#EF7564" }}
                  />
                </div>

                <div
                  onClick={() => setFolderColor("#7BC86C")}
                  style={{ borderWidth: folderColor == "#7BC86C" ? 2 : 0 }}
                  className=" border-slate-10 rounded-full"
                >
                  <div
                    className="w-[20px] h-[20px] rounded-[10px] cursor-pointer m-[5px]"
                    style={{ background: "#7BC86C" }}
                  />
                </div>

                <div
                  onClick={() => setFolderColor("#FFAF3F")}
                  style={{ borderWidth: folderColor == "#FFAF3F" ? 2 : 0 }}
                  className=" border-slate-10 rounded-full"
                >
                  <div
                    className="w-[20px] h-[20px] rounded-[10px] cursor-pointer m-[5px]"
                    style={{ background: "#FFAF3F" }}
                  />
                </div>

                <div
                  onClick={() => setFolderColor("#5BA4CF")}
                  style={{ borderWidth: folderColor == "#5BA4CF" ? 2 : 0 }}
                  className=" border-slate-10 rounded-full"
                >
                  <div
                    className="w-[20px] h-[20px] rounded-[10px] cursor-pointer m-[5px]"
                    style={{ background: "#5BA4CF" }}
                  />
                </div>

                <div
                  onClick={() => setFolderColor("#BBBBBB")}
                  style={{ borderWidth: folderColor == "#BBBBBB" ? 2 : 0 }}
                  className=" border-slate-10 rounded-full"
                >
                  <div
                    className="w-[20px] h-[20px] rounded-[10px] cursor-pointer m-[5px]"
                    style={{ background: "#BBBBBB" }}
                  />
                </div>

                <div
                  onClick={() => setFolderColor("#C36DEC")}
                  style={{ borderWidth: folderColor == "#C36DEC" ? 2 : 0 }}
                  className=" border-slate-10 rounded-full"
                >
                  <div
                    className="w-[20px] h-[20px] rounded-[10px] cursor-pointer m-[5px]"
                    style={{ background: "#C36DEC" }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-[78px] text-center">
              {/* <button className="rounded-[6px] h-[42px] py-[0] w-[160px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] ">
                Create Folder
              </button> */}
              <button
                type="submit"
                // className="btn-filled font-medium bg-blue1 text-white mt-[32px]"
                className={`rounded-[6px] h-[42px] py-[0] w-[160px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] `}
              >
                {loading ? (
                  <span className="loading loading-spinner"></span>
                ) : folder ? (
                  "Update Folder"
                ) : (
                  "Create Folder"
                )}
              </button>
            </div>
          </form>
        </section>
      </div>
    </dialog>
  );
};

export default CreateFolder;
