import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import InfoBoard from "./infoBoard/InfoBoard.jsx";
import cs from "./dashboard.module.scss";
import { images } from "../../helpers/images";
import NavBar from "../../Layout/Navbar.jsx";
import {
  H2,
  H3,
  H4,
  Text2,
} from "../../components/common/loader/TypoGraphy/TypoGraphy.js";
import Badge from "../../components/common/loader/badge.jsx";
import useDashboard from "../../hooks/useDashboard.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ManageSharerModel from "../../components/Modals/ManageSharerModel.jsx";
import InviteSharerModel from "../../components/Modals/InviteSharerModel.jsx";
import { DOCUMENT_STATUS } from "../../helpers/constants.js";
import formatCount from "../../helpers/formatCount.js";
import Error from "../../components/form/Error.jsx";
import LabelDoc from "../../components/form/LabelDoc.jsx";
import UpdateProfile from "./updateProfile/UpdateProfile.jsx";
import AuthServices from "../../services/api/auth.service.js";

const Dashboard = () => {
  const [expandedView, setExpandedView] = useState(false);
  const [openProfileSection, setOpenProfileSection] = useState(false);

  const {
    dashboardCount,
    expiredDocData,
    expiringDocData,
    activeDocData,
    taskCompleted,
  } = useDashboard();
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  console.log("Dashboard");
  const getUserData = useCallback(() => {
    AuthServices.getUserInfo()
      .then((resp) => {
        if (resp.status == 200) {
        }
      })
      .catch((err) => {});
  });

  const handleSetState = (newState) => {
    setOpenProfileSection(newState);
    // getUserData();
  };

  //Status is hardcoded at frontend beacuse api is retrurning wromg "Status"
  function Card(doc, status) {
    return (
      <div
        onClick={() =>
          navigate("/client/viewDocument", { replace: false, state: doc })
        }
        className={cs.card}
      >
        <div className="flex justify-between items-center">
          {/* <Badge type={DOCUMENT_STATUS[doc.Status]} />{" "} */}
          <Badge type={DOCUMENT_STATUS[status]} />{" "}
          <img
            src={images.saved_icon}
            alt="save"
            className="w-[16px] h-[26px]"
          />
        </div>

        <H2 className="mt-[13px] mb-2">{doc?.DocName}</H2>
        <div className="flex justify-between">
          <Text2 className="text-primary4 flex items-center">
            {" "}
            <img
              src={images.folder_icon}
              alt="save"
              className="w-[20px] h-[20px] mr-[9px]"
            />
            {doc?.FolderName}
          </Text2>
          <H4 className="text-xred font-sans">
            <p
              style={{
                color:
                  DOCUMENT_STATUS[status] === "Expired"
                    ? "#F1416C"
                    : DOCUMENT_STATUS[status] === "Expiring"
                    ? "#FFA621"
                    : DOCUMENT_STATUS[status] === "Active"
                    ? "#0BB783"
                    : "black",
              }}
            >
              {dayjs(doc?.ExpiryDate).format("MMM D, YYYY")}
            </p>
          </H4>
        </div>

        {!expandedView && (
          <>
            <div className="flex flex-wrap justify-center md:justify-start mt-[20px] gap-[20px]">
              <div className="w-[150px] h-[70px] px-[15px] py-2 border-2 border-dashed border-primary6 rounded-lg ">
                <H4 className="text-xred font-sans">
                  <p
                    style={{
                      color:
                        DOCUMENT_STATUS[status] === "Expired"
                          ? "#F1416C"
                          : DOCUMENT_STATUS[status] === "Expiring"
                          ? "#FFA621"
                          : DOCUMENT_STATUS[status] === "Active"
                          ? "#0BB783"
                          : "black",
                    }}
                  >
                    {dayjs(doc?.ExpiryDate).format("MMM D, YYYY")}
                  </p>
                </H4>
                <p className="p2-text font-sans">Expiry Date</p>
              </div>
              <div className="w-[160px] h-[70px] px-[15px] py-2 border-2 border-dashed border-primary6 rounded-lg ">
                <H4 className="text-primary4 font-sans">
                  {doc?.DiffTotalDays} day/s{" "}
                </H4>
                <p className="p2-text font-sans">Renewal Overdue</p>
              </div>
            </div>

            <div className=" flex flex-wrap relative justify-between items-center pt-5   mt-[23px]  ">
              <div className={cs.card_border}></div>
              <Text2 className="text-primary4 flex items-center bg-slate-100 py-1 px-2 rounded-md">
                {" "}
                <img
                  src={images.folder_icon}
                  alt="save"
                  className="w-[20px] h-[20px] mr-[9px]"
                />
                Share
              </Text2>

              <div className="flex gap-4">
                <Text2 className="text-primary4 flex items-center">
                  {" "}
                  <img
                    src={images.link_icon}
                    alt="save"
                    className="w-[20px] h-[20px] mr-[5px]"
                  />
                  {doc?.attachmentCount}
                </Text2>
                <Text2 className="text-primary4 flex items-center">
                  {" "}
                  <img
                    src={images.chat_icon}
                    alt="save"
                    className="w-[20px] h-[20px] mr-[5px]"
                  />
                  8
                </Text2>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <ManageSharerModel />
      <UpdateProfile setOpenProfileSection={handleSetState} />
      {/* <InviteSharerModel /> */}
      {/* flex flex-wrap items-center justify-between */}
      <div className="max-w-screen-xl  mx-auto py-7">
        <div className="h-full bg-gray1">
          <InfoBoard
            taskCompleted={taskCompleted}
            dashboardCount={dashboardCount}
            user={user}
            setOpenProfileSection={handleSetState}
          />

          {/* {openProfileSection && (
            <UpdateProfile setOpenProfileSection={handleSetState} />
          )} */}

          {expiredDocData?.Item1?.length == 0 &&
          expiringDocData?.Item1?.length == 0 &&
          activeDocData?.Item1?.length == 0 ? (
            <div className=" bg-white my-6 rounded-[12px] flex flex-col justify-center items-center py-[90px]">
              <img
                src={images.ImgPlaceholder2}
                alt="img"
                className="w-[175px] "
              />
              <p className="h2-text">Let’s Get Started</p>
              <p className="p2-text my-1">
                Start by adding a document to track expiration
              </p>
              <button
                onClick={() =>
                  navigate("/client/addDocument", { replace: true })
                }
                className="btn btn-wide text-white bg-blue1 border-none mt-[60px]"
              >
                Add New Document
              </button>
            </div>
          ) : (
            <div className=" mx-2 md:mx-0 my-12 rounded-[12px] flex flex-col">
              <div className="flex flex-row justify-center xl:justify-between items-center mb-[26px]">
                <div className="flex items-center ">
                  {" "}
                  <H2>Overview </H2>
                  <Text2 className="text-primary4 ml-2">
                    {" "}
                    by Recent Updates ↓
                  </Text2>
                </div>

                <div className="input input-bordered flex items-center gap-2 h-[34px]  ml-3 bg-white">
                  <div
                    className={`dropdown dropdown-end flex justify-center items-center `}
                  >
                    <div tabIndex={0} role="button">
                      {/* <img src={images.menu_vertical_icon} alt="img" /> */}
                      <p className="text-[12px]">
                        {!expandedView ? "Expanded" : "Collapsed"}
                      </p>
                    </div>
                    <ul
                      tabIndex={0}
                      className="dropdown-content z-[1] menu p-2 shadow bg-white  rounded-box w-36"
                    >
                      <li>
                        <p
                          className="text-[12px]"
                          onClick={() => setExpandedView(false)}
                        >
                          Expanded View
                        </p>
                      </li>
                      <li>
                        <p
                          onClick={() => setExpandedView(true)}
                          className="text-[12px]"
                        >
                          Collapsed View
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <section className={`${cs.FlexDashboard} `}>
                <section className={cs.FlexDashboardCol}>
                  <div className="flex flex-row items-center pb-[5px] mb-[33px] border-b-4 border-xred ">
                    <H3 className="font-sans">Expired</H3>
                    <Text2 className="text-primary4 ml-2">
                      {formatCount(expiredDocData?.Item1?.length)}
                    </Text2>
                  </div>
                  <section className={cs.ExpiredCol}>
                    {expiredDocData &&
                      expiredDocData.Item1 &&
                      expiredDocData.Item1.map((item) => Card(item, 3))}
                    {/* {Card("Expired")}
                  {Card("Expired")} */}
                  </section>
                </section>
                <section className={cs.FlexDashboardCol}>
                  <div className="flex flex-row items-center pb-[5px] mb-[33px] border-b-4 border-xyellow ">
                    <H3 className="font-sans">Expiring</H3>
                    <Text2 className="text-primary4 ml-2">
                      {" "}
                      {formatCount(expiringDocData?.Item1?.length)}
                    </Text2>
                  </div>
                  <section className={cs.ExpiredCol}>
                    {expiringDocData &&
                      expiringDocData.Item1 &&
                      expiringDocData.Item1.map((item) => Card(item, 2))}
                  </section>
                </section>
                <section className={cs.FlexDashboardCol}>
                  <div className="flex flex-row items-center pb-[5px] mb-[33px] border-b-4 border-xgreen ">
                    <H3 className="font-sans">Active</H3>
                    <Text2 className="text-primary4 ml-2">
                      {" "}
                      {formatCount(activeDocData?.Item1?.length)}
                    </Text2>
                  </div>
                  <section className={cs.ExpiredCol}>
                    {" "}
                    {activeDocData &&
                      activeDocData.Item1 &&
                      activeDocData.Item1.map((item) => Card(item, 1))}
                  </section>
                </section>
              </section>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
