import React, { useState, useEffect, useCallback } from "react";
import { Text2 } from "../../../components/common/loader/TypoGraphy/TypoGraphy";
import LabelDoc from "../../../components/form/LabelDoc";
import DDLServices from "../../../services/api/ddl.service";
import useUser from "../../../hooks/useUser";
import Error from "../../../components/form/Error";

const UpdateProfile = ({ setOpenProfileSection }) => {
  const [CountriesDDL, setCountriesDDL] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    getCountriesDDL();
  }, []);

  const getCountriesDDL = useCallback(() => {
    DDLServices.GetCoutries()
      .then((resp) => {
        if (resp.status == 200) {
          setCountriesDDL(resp.data);
        }
      })
      .catch((err) => {});
  });

  const {
    register,
    handleSubmit,
    errors,
    watch,
    loading,
    reset,
    setValue,
    updateUserSubmitHandler,
    control,
  } = useUser(uploadedFiles);

  return (
    // <div
    //   className={` flex flex-row justify-center flex-wrap bg-white p-[30px] mt-5`}
    //   style={{ borderRadius: "12px" }}
    // >
    <dialog id="update_profile_modal" className="modal">
      <div className="modal-box  max-w-xl bg-white p-[60px] ">
        {/* if there is a button in form, it will close the modal */}
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <form onSubmit={handleSubmit(updateUserSubmitHandler)}>
          <Text2>Update your photo and personal details here.</Text2>
          <div>
            <div className="mt-[32px] w-100">
              <LabelDoc id="Name" label="Full Name" />
              <input
                type="text"
                {...register("Name")}
                id="Name"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.Name} />
            </div>
            <div className="mt-[20px] w-100">
              <LabelDoc id="PhoneNumber" label="Mobile Number" />
              <input
                type="text"
                {...register("PhoneNumber")}
                id="PhoneNumber"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.PhoneNumber} />
            </div>
            <div className="mt-[20px] w-full">
              <LabelDoc id="CountryId" label="Issued Country" />
              <select
                {...register("CountryId")}
                id="CountryId"
                className="x-doc-input"
                // value={""}
              >
                <option value="">Select Country</option>

                {CountriesDDL &&
                  CountriesDDL?.map((country) => (
                    <option value={country}>{country}</option>
                  ))}
              </select>
              <Error errorName={errors.CountryId} />
            </div>

            <div className="mt-[20px] w-full">
              <LabelDoc id="ProfilePic" label="Your Photo" />
              <input
                type="file"
                {...register("ProfilePic")}
                id="ProfilePic"
                className="x-doc-input"
                placeholder=""
              />
              <Error errorName={errors.ProfilePic} />
            </div>

            <button
              type="submit"
              className={`btn-filled bg-blue1 font-medium text-white mt-[32px] `}
            >
              {loading ? (
                <span className="loading loading-spinner"></span>
              ) : (
                "Update"
              )}
            </button>
            {/* <button
              onClick={() => setOpenProfileSection(false)}
              className={`btn-filled bg-white font-medium text-black mt-[10px] `}
            >
              Cancel
            </button> */}
          </div>
        </form>
      </div>
    </dialog>
    // </div>
  );
};

export default UpdateProfile;
