import React from "react";

const Label = ({ id, label }) => {
  return (
    <label
      htmlFor={id}
      className="block mb-2 text-sm font-medium text-[14px]  text-primary2"
    >
      {label}*
    </label>
  );
};

export default Label;
