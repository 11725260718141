import React from "react";
import cs from "./infoboard.module.scss";
import { images } from "../../../helpers/images";
import {
  H2,
  Text2,
} from "../../../components/common/loader/TypoGraphy/TypoGraphy";
import { useNavigate } from "react-router-dom";
import PricePlanModel from "../../../components/PricePlanModal/PricePlanModel";
import formatCount from "../../../helpers/formatCount";
import useDashboard from "../../../hooks/useDashboard";

const InfoBoard = ({ documentData }) => {
  const { dashboardCount, expiredDocData, expiringDocData, activeDocData } =
    useDashboard();

  const navigate = useNavigate();

  return (
    <div className={cs.info_board}>
      <PricePlanModel />

      <div className={`${cs.info_board_left} flex flex-row flex-wrap`}>
        {/* <div
          className={`${cs.imgDiv} w-[160px] h-[160px] flex flex-col justify-center items-center`}
        >
          <img
            src={images.ImgPlaceholder}
            alt="img"
            className="w-[56px] h-[56px]"
          />
          <p className="p2-text" style={{ color: "#3699FF" }}>
            Add your Photo
          </p>
        </div> */}
        <div className="px-[30px] w-full">
          <div>
            <div className="flex flex-wrap gap-2 md:gap-0 justify-between items-center">
              <p className="h2-text">Documents Overview</p>
             {documentData?.length !== 0 && <button
                onClick={() =>
                  navigate("/client/addDocument", { replace: true })
                }
                className="rounded-[6px] h-[42px] py-[0] w-[176px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] "
              >
                Add New Document
              </button>}
            </div>

            <p className="p2-text mt-1">
              Details about all your documents along with their current status
            </p>
          </div>
          <div className="flex flex-wrap justify-center md:justify-start mt-[20px] gap-[20px]">
            <div className=" w-[100%] md:w-[136px] h-[70px] pl-[15px] py-1 border-2 border-dashed border-primary4 rounded-lg ">
              <p className="h2-text">
                {formatCount(documentData.length) || "00"}
              </p>
              <p className="p2-text">Total Uploaded</p>
            </div>
            <div className="w-[100%] md:w-[130px] h-[70px] px-[15px] py-1  rounded-lg bg-xred ">
              <H2 className="text-white">
                {formatCount(dashboardCount?.ExpiredDoc) || "00"}
              </H2>
              <Text2 className="text-white">Expired</Text2>
            </div>
            <div className="w-[100%] md:w-[130px] h-[70px] px-[15px] py-1  rounded-lg bg-xyellow ">
              <H2 className="text-white">
                {" "}
                {formatCount(dashboardCount?.ExpiringDoc) || "00"}
              </H2>
              <Text2 className="text-white">Expiring</Text2>
            </div>
            <div className="w-[100%] md:w-[130px] h-[70px] px-[15px] py-1  rounded-lg bg-xgreen ">
              <H2 className="text-white">
                {formatCount(dashboardCount?.ActiveDoc) || "00"}
              </H2>
              <Text2 className="text-white">Active</Text2>
            </div>
          </div>
        </div>
        {/* <div className="flex items-start py-3">
          <button className="btn w-[176px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] ">
            Add New Document
          </button>
        </div> */}
      </div>
      <div
        className={`${cs.info_board_right} flex flex-col items-center justify-center gap-2 `}
      >
        <img src={images.vector1} alt="img" />
        <p className="text-[16px] font-[700] text-primary5 font-sans">
          Upgrade to Pro
          <br />
          Track Limitless Documents
        </p>
        <button
          onClick={() =>
            document.getElementById("upgrade_plan_modal").showModal()
          }
          className="rounded-[6px] w-[115px] text-white bg-blue1 border-none  font-sans font-medium text-[12px] py-[10px] px-[16px]"
        >
          Upgrade Now
        </button>
      </div>
    </div>
  );
};

export default InfoBoard;
