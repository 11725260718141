import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cs from "./infoboard.module.scss";
import { images } from "../../../helpers/images";
import PricePlanModel from "../../../components/PricePlanModal/PricePlanModel";
import { SelectFolder } from "../../../redux/features/folderSlice";

const InfoBoard = ({ folderData }) => {
  const dispatch = useDispatch();

  return (
    <div className={cs.info_board}>
      <PricePlanModel />
      <div className={`${cs.info_board_left} flex flex-row flex-wrap`}>
        {/* <div
          className={`${cs.imgDiv} w-[160px] h-[160px] flex flex-col justify-center items-center`}
        >
          <img
            src={images.ImgPlaceholder}
            alt="img"
            className="w-[56px] h-[56px]"
          />
          <p className="p2-text" style={{ color: "#3699FF" }}>
            Add your Photo
          </p>
        </div> */}
        <div className="px-[30px] w-full">
          <div className="flex flex-wrap justify-between items-center">
            <p className="h2-text">Folders</p>
            {folderData?.length !== 0 && (
              <button
                onClick={() => {
                  dispatch(SelectFolder(null));
                  document.getElementById("create_folder_modal").showModal();
                }}
                className="rounded-[6px] h-[42px] py-[0] w-[118px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] "
              >
                Create New
              </button>
            )}
          </div>
          <p className="p2-text mt-1">
            Organize your documents effortless in the folders of your choice
          </p>
          <div className="flex flex-wrap justify-center md:justify-start mt-[20px] gap-[20px]">
            <div className="w-[100%] md:w-[136px] h-[70px] pl-[15px] py-1 border-2 border-dashed border-primary4 rounded-lg ">
              <p className="h2-text">{folderData?.length}</p>
              <p className="p2-text">Total Folders</p>
            </div>
          </div>
        </div>
        {/* <div className="flex items-start py-3">
          <button className="btn w-[118px] text-white bg-blue1 border-none  font-sans font-medium text-[15px] ">
            Create New
          </button>
        </div> */}
      </div>
      <div
        className={`${cs.info_board_right} flex flex-col items-center justify-center gap-2 `}
      >
        <img src={images.vector1} alt="img" />
        <p className="text-[16px] font-[700] text-primary5 font-sans">
          Upgrade to Pro
          <br />
          Track Limitless Documents
        </p>
        <button
          onClick={() =>
            document.getElementById("upgrade_plan_modal").showModal()
          }
          className="rounded-[6px] w-[115px] text-white bg-blue1 border-none  font-sans font-medium text-[12px] py-[10px] px-[16px]"
        >
          Upgrade Now
        </button>
      </div>
    </div>
  );
};

export default InfoBoard;
