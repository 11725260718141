import { client } from "../interfaces/api-urls";
import { getRequest, postRequest } from "../interfaces/axios-methods";

// async function GetFolders() {
//   try {
//     const response = await getRequest(
//       client.GET_FOLDER_DATA_TABLE + "?orderBy=1"
//     );
//     return await response?.data;
//   } catch (error) {
//     return await error;
//   }

// }

const FolderServices = {
  GetFolders: async () => {
    return getRequest(client.GET_FOLDER_DATA_TABLE + "?orderBy=1");
  },

  createFolder: async (body) => {
    return postRequest(client.CREATE_FOLDER, body);
  },

  updateFolder: async (body) => {
    return postRequest(client.UPDATE_FOLDER, body);
  },

  deleteFolder: async (id) => {
    return getRequest(client.DELETE_FOLDER + "?Id=" + id);
  },
};

export default FolderServices;
