import * as Yup from "yup";

export const loginValidation = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const signUpValidation = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export const createFolderValidation = Yup.object().shape({
  name: Yup.string().required("Folder Name is required"),
});

export const createDocumentValidation = Yup.object().shape({
  DocName: Yup.string().required("Document Name is required"),
  ExpiryDate: Yup.string().required("Expiration Date is required"),
  // IssueDate: Yup.string().required("Issue Date is required"),

  FolderId: Yup.string().required("Folder is required"),
  CountryId: Yup.string(),
  // .required("Country is required"),
  StateId: Yup.string(),
  // .required("State is required"),
  DocTypeId: Yup.string().required("Document Type is required"),
  DocNumber: Yup.string().required("Document Number is required"),
  RemindMe: Yup.boolean(),
});

export const createTaskValidation = Yup.object().shape({
  TaskName: Yup.string().required("Title is required"),
  DueDate: Yup.string().required("Due Date is required"),
  Description: Yup.string(),
  AssignTo: Yup.string(),
});

export const createSharerValidation = Yup.object().shape({
  name: Yup.string().required("Sharer name is required"),
  email: Yup.string()
    .required("Sharer's Email is required")
    .email("Invalid email address"),
});

export const paymentPlanValidation = Yup.object().shape({
  username: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  cardNumber: Yup.string().required("Card Number is required"),
  cvc: Yup.string().required("CVC Number is required"),
  expirationMonth: Yup.number()
    .required("Expiration Month is required")
    .min(0, "Enter month between 0 to 12")
    .max(12, "Enter month between 0 to 12"),
  expirationYear: Yup.number()
    .required("Expiration Year is required.")
    .min(2024, "Enter year after 2024"),
});

export const updateUserValidation = Yup.object().shape({
  Name: Yup.string().required("Name is required"),
  PhoneNumber: Yup.string().required("Phone number is required"),
  CountryId: Yup.string().required("Country is required"),
  ProfilePic: Yup.mixed().required("ProfilePic is required"),
});

// username: Yup.string()
// .min(3, 'Must be atleast 3 characters long')
// .trim('Whitespaces are not allowed')
// .strict(true)
// .concat(
//   !isPasswordReset
//     ? Yup.string()
//         .required('Username is required')
//         .matches(/^[A-Za-z0-9]+$/, 'Only alphanumeric values are allowed')
//     : null
// ),
// phoneNumber: Yup.string()
// .min(10, 'Please enter complete number')
// .concat(!isPasswordReset ? Yup.string().required('Phone Number is required') : null),
// email: Yup.string()
// .email('Enter valid email address')
// .concat(!isPasswordReset ? Yup.string().required('Email is required') : null),
// password: Yup.string()
// .trim('Whitespaces are not allowed')
// .strict(true)
// .concat(!isEdit || isPasswordReset ? Yup.string().required('Password is required') : null),
// cpassword: Yup.string()
// .trim('Whitespaces are not allowed')
// .strict(true)
// .when('password', (password, schema) => {
//   if (password || !isEdit || isPasswordReset)
//     return schema.required('Confirm Password is required');
// })
// .oneOf([Yup.ref('password')], 'Passwords must match'),
