import logo from "../assets/logo.svg";
import Xpireelogo from "../assets/xpiree-logo.svg";
import XpireelogoBlue from "../assets/xpiree-logo-blue.svg";
import ImgPlaceholder from "../assets/ImgPlaceholder.svg";
import ImgPlaceholder2 from "../assets/ImgPlaceholder2.svg";
import vector1 from "../assets/vector1.svg";
import folder from "../assets/folder.svg";
import stripe_button from "../assets/stripe_button.svg";
import uploadFile from "../assets/uploadFile.svg";
import pdf from "../assets/pdf.svg";
import jpg from "../assets/jpg.svg";
import file from "../assets/file.png";

import caution from "../assets/caution.svg";
import person from "../assets/person.png";

import google_icon from "../assets/Icons/google.svg";
import settings_icon from "../assets/Icons/settings.svg";
import menu_vertical_icon from "../assets/Icons/menu_vertical.svg";
import save_icon from "../assets/Icons/save.svg";
import saved_icon from "../assets/Icons/saved.svg";
import folder_icon from "../assets/Icons/folder.svg";
import link_icon from "../assets/Icons/link.svg";
import chat_icon from "../assets/Icons/chat.svg";
import edit_icon from "../assets/Icons/edit.svg";
import done_icon from "../assets/Icons/done.svg";
import delete_icon from "../assets/Icons/delete.svg";
import bell_icon from "../assets/Icons/bell.svg";
import cross_icon from "../assets/Icons/cross.svg";
import hide_icon from "../assets/Icons/hide.svg";

export const images = {
  logo,
  Xpireelogo,
  XpireelogoBlue,
  ImgPlaceholder,
  ImgPlaceholder2,
  vector1,
  folder,
  stripe_button,
  uploadFile,
  pdf,
  jpg,
  file,
  caution,
  person,

  google_icon,
  settings_icon,
  menu_vertical_icon,
  save_icon,
  saved_icon,
  folder_icon,
  link_icon,
  chat_icon,
  edit_icon,
  delete_icon,
  done_icon,
  bell_icon,
  cross_icon,
  hide_icon,
};
