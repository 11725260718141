import React, { useState } from "react";

import cs from "./login.module.scss";
import { images } from "../../helpers/images";
import Label from "../../components/form/Label";

import Error from "../../components/form/Error";
import useLogin from "../../hooks/useLogin";
import { Link } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    errors,
    LoginSubmitHandler,
    ForgetPasswordHandler,
    loading,
  } = useLogin();
  console.log("Login");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <img
        src={images.XpireelogoBlue}
        alt="Xpiree Logo"
        className="w-[180px] h-[78px] absolute left-[30px] top-[15px] "
      />
      <section className={cs.FlexContainer}>
        <div className={cs.FlexCard1}></div>
        <div className={`${cs.FlexCard2} f-center`}>
          <div className={cs.login_container}>
            <p className="h-text w-max">Log in to your account</p>
            <p className="p-text mt-[12px]">Welcome to Xpiree!</p>

            {/* <button className="btn-filled mt-[32px]">Sign up with Google</button> */}
            <button
              type="button"
              className="btn-filled text-primary2 mt-[32px] justify-center  bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-semibold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 "
            >
              <img
                src={images.google_icon}
                alt="Google Logo"
                className="w-6 h-6 me-2"
              />
              Log in with Google
            </button>
            <form onSubmit={handleSubmit(LoginSubmitHandler)}>
              <div className="mt-[20px]">
                <Label id="user_email" label="Email" />
                <input
                  type="text"
                  {...register("email")}
                  id="user_email"
                  className="x-input"
                  placeholder="example@xpiree.com"
                />
                <Error errorName={errors.email} />
              </div>

              {/* //NOTE - Password */}
              <div className="mt-[20px] relative">
                <Label id="user_password" label="Password" />
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  id="user_password"
                  className="x-input"
                  placeholder="••••••••"
                />
                <span
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    "👁️"
                  ) : (
                    <img
                      src={images.hide_icon}
                      alt="hide"
                      className="w-4 h-4 opacity-50 mt-1"
                    />
                  )}
                </span>

                <Error errorName={errors.password} />
              </div>

              <div className="flex items-center justify-between mt-[20px] ">
                <div className="flex items-start  ">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      {...register("rememberMe")}
                      type="checkbox"
                      value=""
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <label
                    htmlFor="remember"
                    className="ms-2 text-sm font-medium text-primary3 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
                <p
                  type="submit"
                  onClick={() => ForgetPasswordHandler()}
                  className="font-semibold cursor-pointer text-sm text-blue-400 hover:underline"
                >
                  Forgot password
                </p>
              </div>

              <button
                type="submit"
                className="btn-filled bg-blue1 text-white mt-[32px]"
              >
                {loading ? (
                  <span className="loading loading-spinner"></span>
                ) : (
                  "Sign in"
                )}
              </button>
            </form>

            <div className="flex items-center justify-center  mt-[20px]">
              <label
                htmlFor="remember"
                className="ms-2 text-sm font-medium text-primary3 dark:text-gray-300"
              >
                Don’t have an account?{" "}
                <Link
                  to="/signup"
                  className="font-semibold text-blue-400 hover:underline"
                >
                  Sign up
                </Link>
              </label>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
