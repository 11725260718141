import React, { useEffect, useState } from "react";
import { H1, H2, Text2 } from "../common/loader/TypoGraphy/TypoGraphy";
import { images } from "../../helpers/images";
import UpgradePlan from "./UpgradePlan";

const Pricing = () => {
  const [isUpgradePlan, setIsUpgradePlan] = useState(false);
  useEffect(() => {
    setIsUpgradePlan(false);
  }, []);
  return (
    <div>
      <section class=" md:p-5">
        <div class=" max-w-screen-xl ">
          <div class="mx-auto max-w-3xl text-center pb-12 md:pb-20">
            <H1>Choose Your Plan</H1>
            <Text2 class="text-[15px]">
              Get additional power and control to manage document expirations
              more effectively.
            </Text2>
          </div>
          {!isUpgradePlan && (
            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              <div class="flex flex-col w-[320px] h-[520px]  bg-primary8 p-6 mx-auto max-w-md text-center rounded-box shadow-xl xl:p-8  border-[3px]  shadow-primary/10 border-green-400 transition hover:border-primary-focus/20 hover:shadow-primary-focus/20">
                {/* <h3 class="mb-4 text-2xl font-semibold">Free</h3> */}
                <H2 className="text-[22px]">Basic</H2>
                <div class="flex justify-center items-baseline my-1 h-10">
                  <span
                    className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-blue-100 text-blue3`}
                  >
                    Current Plan
                  </span>
                  {/* <p class="font-light sm:text-lg">
                  No credit card required. Ever
                </p> */}
                </div>
                <div class="flex justify-center items-baseline mb-[30px]">
                  <span class="mr-2 text-[28px] text-blue3 font-extrabold">
                    Free
                  </span>
                  <span class="">/month</span>
                </div>
                <ul role="list" class="mb-8 space-y-4 text-left">
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Up to 25 documents</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Up to 05 folders</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Add up to 10 sharers</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">
                      Share up to 3 documents per sharer
                    </Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">
                      Attach upto 2 files per document
                    </Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Ads included</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">500 MB media storage</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                </ul>
              </div>
              <div class="flex flex-col w-[320px] h-[520px] bg-primary8 p-6 mx-auto max-w-md text-center rounded-box shadow-xl xl:p-8  border border-base-300 shadow-primary/10 border-primary/10 transition hover:border-primary-focus/20 hover:shadow-primary-focus/20">
                {/* <h3 class="mb-4 text-2xl font-semibold">Free</h3> */}
                <H2 className="text-[22px]">Personal</H2>
                <div class="flex justify-center items-baseline my-1 h-10">
                  <Text2 className="text-primary4">Best for a Family</Text2>
                  {/* <p class="font-light sm:text-lg">
                  No credit card required. Ever
                </p> */}
                </div>
                <div class="flex justify-center items-baseline mb-[30px]">
                  <span class="mr-2 text-[38px] text-blue3 font-extrabold">
                    $05
                  </span>
                  <span class="">/month</span>
                </div>
                <ul role="list" class="mb-8 space-y-4 text-left">
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Up to 50 documents</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Up to 15 folders</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Add up to 15 sharers</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">
                      Create tasks, add due date <br />
                      and assign to sharers
                    </Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">02 GB media storage</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                </ul>
                <button
                  onClick={() => setIsUpgradePlan(true)}
                  className="rounded-[6px] self-center h-[42px] py-[0] w-[176px] text-white bg-blue1 border-none  font-sans font-medium text-[12px] "
                >
                  Upgrade to Personal
                </button>
              </div>
              <div class="flex flex-col w-[320px] h-[520px] bg-primary8 p-6 mx-auto max-w-md text-center rounded-box shadow-xl xl:p-8  border border-base-300 shadow-primary/10 border-primary/10 transition hover:border-primary-focus/20 hover:shadow-primary-focus/20">
                {/* <h3 class="mb-4 text-2xl font-semibold">Free</h3> */}
                <H2 className="text-[22px]">Professional</H2>
                <div class="flex justify-center items-baseline my-1 h-10">
                  <Text2 className="text-primary4">
                    Best for an Enterprise
                  </Text2>
                  {/* <p class="font-light sm:text-lg">
                  No credit card required. Ever
                </p> */}
                </div>
                <div class="flex justify-center items-baseline mb-[30px]">
                  <span class="mr-2 text-[38px] text-blue3 font-extrabold">
                    $10
                  </span>
                  <span class="">/month</span>
                </div>
                <ul role="list" class="mb-8 space-y-4 text-left">
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Unlimited documents</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Unlimited folders</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">Unlimited sharers</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">
                      {" "}
                      Create tasks, add due date <br />
                      and assign to sharers
                    </Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                  <li class="flex items-center justify-between space-x-3">
                    {/* <span class="text-base-content/80">Use your own browser</span> */}
                    <Text2 className="font-medium">05 GB media storage</Text2>

                    <img
                      src={images.done_icon}
                      className="w-[18px] h-[18px]"
                      alt="done"
                    />
                  </li>
                </ul>
                {/* <button class="w-full font-bold gap-2 shadow uppercase p-2 bg-teal-500 text-white">
                Get Started
              </button> */}
                <button
                  onClick={() => setIsUpgradePlan(true)}
                  className="rounded-[6px] self-center h-[42px] py-[0] w-[176px] text-white bg-blue1 border-none  font-sans font-medium text-[12px] "
                >
                  Upgrade to Professional
                </button>
              </div>
            </div>
          )}

          {isUpgradePlan && <UpgradePlan setIsUpgradePlan={setIsUpgradePlan} />}
          <div class="mx-auto w-full  flex items-center justify-center pt-[37px] ">
            <img
              src={images.stripe_button}
              // className="w-[18px] h-[18px]"
              alt="stripe"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
