import { client } from "../interfaces/api-urls";
import { getRequest, postRequest } from "../interfaces/axios-methods";

const DocumentServices = {
  GetDocuments: async () => {
    return getRequest(client.GET_DOCUMENT_DATA_TABLE + "?orderBy=1");
  },

  GetDocumentById: async (id) => {
    return getRequest(client.GET_DOCUMENT_BY_ID + "?DocId=" + id);
  },

  deleteDocument: async (id) => {
    return getRequest(client.DELETE_DOCUMENT + "?docId=" + id);
  },

  createDocument: async (body) => {
    return postRequest(client.CREATE_DOCUMENT, body);
  },

  updateUserDocStatus: async (body) => {
    return getRequest(
      client.UPDATE_DOC_STATUS +
        "?docId=" +
        body.id +
        "&docStatus=" +
        body.status
    );
  },
};

export default DocumentServices;
