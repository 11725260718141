import { client } from "../interfaces/api-urls";
import { getRequest, postRequest } from "../interfaces/axios-methods";

const TaskServices = {
  GetAssignedToMe: async () => {
    return getRequest(client.getAssignedToMeTaskList + "?orderBy=1");
  },

  GetAssignedByMe: async () => {
    return getRequest(client.getAssignedByMeTaskList + "?orderBy=1");
  },

  GetCompletedTask: async () => {
    return getRequest(client.getCompletedTaskList + "?orderBy=1");
  },

  GetTasksByDocId: async (id) => {
    return getRequest(client.getAssignToMeTaskByDocId + "?docId=" + id);
  },

  updateTaskIsCompleted: async (id, status) => {
    return getRequest(
      client.updateTaskIscompleted + "?taskId=" + id + "&isCompleted=" + status
    );
  },

  createTask: async (body) => {
    return postRequest(client.createTask, body);
  },

  //   updateTask: async (body) => {
  //     return postRequest(client.UPDATE_FOLDER, body);
  //   },

  //   deleteTask: async (id) => {
  //     return getRequest(client.DELETE_FOLDER + "?Id=" + id);
  //   },
};

export default TaskServices;
