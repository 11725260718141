import React from "react";
import Pricing from "../Pricing/Pricing";

const PricePlanModel = () => {
  return (
    <dialog id="upgrade_plan_modal" className="modal">
      <div className="modal-box w-11/12 max-w-7xl bg-white">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <Pricing />
      </div>
    </dialog>
  );
};

export default PricePlanModel;
