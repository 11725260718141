import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  paymentPlanValidation,
  signUpValidation,
} from "../helpers/validations";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AuthServices from "../services/api/auth.service";
import ProfileServices from "../services/api/profile.service";

const usePaymentPlan = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(paymentPlanValidation),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const upgradePlanSubmitHandler = (data) => {
    const payload = {
      Name: data.username,
      email: data.email,
      CreditCard: {
        cardNumber: data.cardNumber,
        cvc: data.cvc,
        expirationMonth: String(data.expirationMonth),
        expirationYear: String(data.expirationYear),
        name: data.username,
      },
    };

    setLoading(true);

    ProfileServices.addStripeCustomer(payload)
      .then((resp) => {
        if (resp.status == 200) {
          toast.success("Success");

          // reset();
          // navigate("/login");
        } else {
          toast.error("Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  return {
    register,
    handleSubmit,
    errors,
    upgradePlanSubmitHandler,
    loading,
    watch,
  };
};

export default usePaymentPlan;
