import React, { useCallback, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import NavBar from "../../Layout/Navbar";
import InfoBoard from "./infoBoard/InfoBoard";
import { images } from "../../helpers/images";
import DocumentServices from "../../services/api/document.service";
import { truncateText } from "../../helpers/truncateText";
import { useNavigate } from "react-router-dom";

const Calendar = () => {
  const navigate = useNavigate();
  const events = [
    { id: 1, title: "Today", start: new Date(), className: "text-xred" },
    // { title: "Today2", start: new Date()  },
  ];

  // a custom render function
  function renderEventContent(eventInfo) {
    return (
      <div
        onClick={() =>
          navigate("/client/viewDocument", {
            replace: false,
            state: { Id: eventInfo.event.id },
          })
        }
      >
        <i>{eventInfo.event.title}</i>
      </div>
    );
  }

  const [documentData, setDocumentData] = useState([]);
  const [ExpiredThisMonth, setExpiredThisMonth] = useState(null);
  const [ExpiringThisMonth, setExpiringThisMonth] = useState(null);

  useEffect(() => {
    getDocumentData();
  }, []);

  const getDocumentData = useCallback(() => {
    DocumentServices.GetDocuments()
      .then((resp) => {
        if (resp.status == 200) {
          // setDocumentData(resp.data.result);

          const current = resp.data.result.filter((x) =>
            isDateInCurrentMonth(x?.ExpiryDate)
          );
          const expiredThisMonth = current.filter(
            (x) => new Date(x?.ExpiryDate) < new Date()
          );
          console.log(current.length, expiredThisMonth.length);

          setExpiredThisMonth(expiredThisMonth.length);
          setExpiringThisMonth(current.length - expiredThisMonth.length);

          const temp = resp.data.result.map((x) => {
            return {
              id: x.Id,
              title: truncateText(x?.DocName, 20),
              start: new Date(x?.ExpiryDate),
              className: `px-2 py-1 mb-1 text-white ${
                x?.Status == 3 ? "bg-xred" : "bg-xyellow"
              }`,
              // className: `calender_date  ${
              //   x?.Status == 3 ? "calender_expired " : "calender_expiring"
              // }`,
            };
          });

          setDocumentData(temp);
        }
      })
      .catch((err) => {
        // toast.error("Failed");
      });
  });

  function isDateInCurrentMonth(date) {
    // Get the current date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Months are zero-based (0-11)
    const currentYear = currentDate.getFullYear();

    // Get the month and year of the given date
    const givenDate = new Date(date);
    const givenMonth = givenDate.getMonth();
    const givenYear = givenDate.getFullYear();

    // Check if the given date is in the current month and year
    return currentMonth === givenMonth && currentYear === givenYear;
  }

  return (
    <>
      <NavBar />

      {/* flex flex-wrap items-center justify-between */}
      <div className="max-w-screen-xl  mx-auto py-7">
        <div className="h-full bg-gray1">
          <InfoBoard
            ExpiredThisMonth={ExpiredThisMonth}
            ExpiringThisMonth={ExpiringThisMonth}
          />

          <div className=" bg-white my-6 rounded-[12px] flex flex-col justify-center items-center py-[90px]">
            <div
              style={{
                width: "100%",
                height: "100%",
                paddingRight: "50px",
                paddingLeft: "50px",
              }}
            >
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={documentData}
                eventContent={renderEventContent}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
