import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Badge from "../../components/common/loader/badge";
import { images } from "../../helpers/images";
import { DOCUMENT_STATUS } from "../../helpers/constants";
import Swal from "sweetalert2";
import DocumentServices from "../../services/api/document.service";
import { toast } from "react-toastify";
import {
  H2,
  Text2,
} from "../../components/common/loader/TypoGraphy/TypoGraphy";
import { useNavigate } from "react-router-dom";

const EditDeleteButton = ({ doc, getDocumentData }) => {
  const navigate = useNavigate();
  const confirmDelete = (document) => {
    Swal.fire({
      title: "Are you sure?",
      text: "The document will be removed permanently from the account.",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `Don't delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        DocumentServices.deleteDocument(document.Id)
          .then((resp) => {
            if (resp.status == 200) {
              toast.success("Document Deleted!", "", "success");
              getDocumentData();
            }
          })
          .catch((err) => {
            toast.error("Failed");
          });
      }
    });
  };

  return (
    <div className="flex">
      <img
        src={images.edit_icon}
        className="cursor-pointer"
        alt="edit"
        // , { replace: true }
        onClick={() =>
          navigate("/client/addDocument", { replace: false, state: doc })
        }
      />
      <img
        src={images.delete_icon}
        alt="delete"
        className=" ml-[15px] cursor-pointer"
        onClick={() => confirmDelete(doc)}
      />
    </div>
  );
};

const ProfileStack = () => {
  return (
    <div class="flex -space-x-4 rtl:space-x-reverse">
      <img
        class="relative bg-primary6 border-2 border-white rounded-full inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full dark:bg-gray-600"
        src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
        alt=""
      />
      <div class="relative bg-primary6 border-2 border-white rounded-full inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-500 rounded-full dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">JL</span>
      </div>

      <div class="relative bg-primary6 border-2 border-white rounded-full inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full dark:bg-gray-600">
        <span class="font-medium text-xs text-gray-600 dark:text-gray-300">
          {" "}
          +99
        </span>
      </div>
    </div>
  );
};

const DocTable = ({ documentData, getDocumentData }) => {
  const [filteredCategory, setFilteredCategory] = useState("Show All");

  const itemsPerPage = 10;
  const totalPages = Math.ceil(documentData.length / itemsPerPage);

  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState(
    documentData.slice(0, itemsPerPage)
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(documentData);

  useEffect(() => {
    const start = (pageNumber - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setTableData(filteredData.slice(start, end));
  }, [pageNumber, filteredData]);

  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("UseEFFect");
  //   setFilteredData(
  //     documentData.filter((doc) =>
  //       Object.values(doc).some((value) =>
  //         value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     )
  //   );
  //   setPageNumber(1); // Reset to first page when search query changes
  // }, [searchQuery, documentData]);

  useEffect(() => {
    const filtered = documentData.filter((doc) =>
      Object.values(doc).some((value) =>
        value?.toString()?.toLowerCase()?.includes(searchQuery.toLowerCase())
      )
    );

    const categoryFiltered = filtered.filter((doc) => {
      if (filteredCategory === "Show All") return true;
      return DOCUMENT_STATUS[doc?.Status] === filteredCategory;
    });

    setFilteredData(categoryFiltered);
    setPageNumber(1); // Reset to first page when search query or category changes
  }, [searchQuery, filteredCategory, documentData]);

  const handlePrev = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="flex justify-between ">
        <div className="flex flex-row justify-center xl:justify-start items-center mb-[26px]">
          <H2>My Documents</H2>
          <Text2 className="text-primary4 ml-2"> ({documentData.length})</Text2>
        </div>

        <div className="flex justify-center  ">
          <div className="flex ">
            <label
              style={{ background: "white" }}
              className="input input-bordered flex items-center gap-2 h-[34px]   "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                type="text"
                className="w-[135px] text-[12px] "
                // style={{ background: "white" }}
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />

              {/* <details className="dropdown">
                  <summary className=" bg-white w-[122px] h-[34px]">
                    View All
                  </summary>
                  <ul className="p-2 bg-white shadow menu dropdown-content z-[1]  rounded-box w-52">
                    <li>
                      <a>Item 1</a>
                    </li>
                    <li>
                      <a>Item 2</a>
                    </li>
                  </ul>
                </details> */}
            </label>
          </div>

          <div className="input input-bordered flex items-center gap-2 h-[34px]  ml-3 bg-white">
            <div
              className={`dropdown dropdown-end flex justify-center items-center `}
            >
              <div tabIndex={0} role="button">
                {/* <img src={images.menu_vertical_icon} alt="img" /> */}
                <p className="text-[12px]">{filteredCategory}</p>
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-white  rounded-box w-36"
              >
                <li>
                  <p onClick={() => setFilteredCategory("Show All")}>
                    Show All
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => setFilteredCategory("Expired")}
                    className="text-xred"
                  >
                    Expired
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => setFilteredCategory("Expiring")}
                    className="text-xyellow"
                  >
                    Expiring
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => setFilteredCategory("Active")}
                    className="text-xgreen"
                  >
                    Active
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="overflow-x-auto mt-[20px]">
          <table className="table ">
            {/* head */}
            <thead>
              <tr className="bg-gray1">
                {/* <th></th> */}
                <th>Document Name</th>
                <th>Expiration Date</th>
                <th>Sharing</th>
                <th>Folder</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {documentData &&
                tableData.map((doc, index) => (
                  <tr>
                    <td
                      onClick={() =>
                        navigate("/client/viewDocument", {
                          replace: false,
                          state: doc,
                        })
                      }
                      className="text-primary5 font-medium cursor-pointer hover:font-bold"
                    >
                      {doc?.DocName}
                    </td>

                    <td
                      className={
                        doc?.Status == 3 ? "text-xred" : "text-primary7"
                      }
                    >
                      {dayjs(doc?.ExpiryDate).format("MMM D, YYYY")}
                    </td>
                    <td>{/* <ProfileStack /> */}-</td>
                    <td>{doc?.FolderName}</td>
                    <td>
                      <Badge type={DOCUMENT_STATUS[doc?.Status]} />
                    </td>
                    <td>
                      <EditDeleteButton
                        doc={doc}
                        getDocumentData={getDocumentData}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {tableData.length > 0 ? (
            tableData.map((doc, index) => (
              <tr key={index}>{/* Render your table row here */}</tr>
            ))
          ) : (
            <p className="text-center w-100 m-5">No data found</p>
          )}

          <div
            style={{ visibility: tableData.length == 0 ? "hidden" : "visible" }}
            className="flex justify-between items-center mt-3"
          >
            <span class="text-sm text-gray-700 dark:text-gray-400">
              Showing{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {(pageNumber - 1) * itemsPerPage + 1}
              </span>{" "}
              to{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {" "}
                {Math.min(pageNumber * itemsPerPage, filteredData.length)}
              </span>{" "}
              of{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {filteredData.length}
              </span>{" "}
              Entries
            </span>

            <div class="inline-flex mt-2 xs:mt-0">
              <button
                onClick={handlePrev}
                disabled={pageNumber === 1}
                class="flex items-center justify-center px-3 h-8 text-sm font-medium bg-gray-800 rounded-s hover:bg-gray-900 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Prev
              </button>
              <button
                onClick={handleNext}
                disabled={pageNumber === totalPages}
                class="flex items-center justify-center px-3 h-8 text-sm font-medium  bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocTable;
