import React, { useState, useEffect, useCallback } from "react";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";

import cs from "./adddocument.module.scss";
import NavBar from "../../Layout/Navbar";
import {
  H2,
  H3,
  Text3,
} from "../../components/common/loader/TypoGraphy/TypoGraphy";
import Label from "../../components/form/Label";
import Error from "../../components/form/Error";
import LabelDoc from "../../components/form/LabelDoc";
import { images } from "../../helpers/images";
import { bytesToMB } from "../../helpers/bytesToMB";
import PricePlanModel from "../../components/PricePlanModal/PricePlanModel";
import DDLServices from "../../services/api/ddl.service";
import useDocumentSubmit from "../../hooks/useDocumentSubmit";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import DocumentServices from "../../services/api/document.service";
import { Link } from "react-router-dom";

const AddDocuments = () => {
  const [selectedForm, setSelectedForm] = useState(1);
  const [activeTabs, setActiveTabs] = useState([1]);
  const [HaveDate, setHaveDate] = useState(false);
  const [reminderToggle, setreminderToggle] = useState(false);
  const [NoOfReminders, setNoOfReminders] = useState([1, 2]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDefaultReminder, setIsDefaultReminder] = useState(false);

  const [reminders, setReminders] = useState([
    {
      CustomRemindValue: 1,
      CustomRemindPeriod: "",
      RemindValue: 5,
      selected: true,
    },
  ]);

  const validateReminder = (value, unit) => {
    console.log(value, unit);
    const weekInvalidValues = [2, 4, 8, 24];
    const monthInvalidValues = [1, 2, 6];
    if (
      (unit === "Weeks" && weekInvalidValues.includes(parseInt(value))) ||
      (unit === "Months" && monthInvalidValues.includes(parseInt(value)))
    ) {
      toast.info("This is already default reminder");
      setIsDefaultReminder(true);
    } else {
      setIsDefaultReminder(false);
    }
  };

  const handleValueChange = (index, newValue) => {
    const updatedReminders = reminders.map((reminder, i) => {
      if (i === index) {
        validateReminder(newValue, reminder.unit);
        return { ...reminder, CustomRemindValue: Number(newValue) };
      }
      return reminder;
    });
    setReminders(updatedReminders);
  };

  const handleUnitChange = (index, newUnit) => {
    const updatedReminders = reminders.map((reminder, i) => {
      if (i === index) {
        validateReminder(reminder.CustomRemindValue, newUnit);
        return { ...reminder, CustomRemindPeriod: newUnit };
      }
      return reminder;
    });
    setReminders(updatedReminders);
  };

  const addReminder = () => {
    if (reminders.length < 3 && !isDefaultReminder) {
      setReminders([
        ...reminders,
        {
          CustomRemindValue: "",
          CustomRemindPeriod: "Weeks",
          RemindValue: 5 + reminders.length,
          selected: true,
        },
      ]);
    }
  };

  const removeReminder = (index) => {
    setReminders(reminders.filter((_, i) => i !== index));
  };

  const [startDate, setStartDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState(new Date());

  const [UserFoldersDDL, setUserFoldersDDL] = useState(null);
  const [CountriesDDL, setCountriesDDL] = useState(null);
  const [StatesDDL, setStatesDDL] = useState(null);
  const [DocTypeDDL, setDocTypeDDL] = useState(null);
  const [DocData, setDocData] = useState(null);

  const location = useLocation();
  const doc = location.state || {};

  useEffect(() => {
    doc && doc.Id && getDocumentDataById();
    if (!DocData) {
      getUserFoldersDDL();
      geCountriesDDL();
      getDocTypeDDL();
    }
  }, []);
  // useEffect(() => {
  //   getUserFoldersDDL();
  //   geCountriesDDL();
  //   getDocTypeDDL();
  // }, []);

  const getUserFoldersDDL = useCallback((fid) => {
    DDLServices.GetUserFolders()
      .then((resp) => {
        if (resp.status == 200) {
          setUserFoldersDDL(resp.data.result);
          fid && setValue("FolderId", fid);
        }
      })
      .catch((err) => {});
  });

  const geCountriesDDL = useCallback((cid, sid) => {
    DDLServices.GetCoutries()
      .then((resp) => {
        if (resp.status == 200) {
          if (cid) {
            setValue("CountryId", cid);
            getStatsDDL(cid, sid);
          }
          setCountriesDDL(resp.data);
        }
      })
      .catch((err) => {});
  });

  const [selectedState, setSelectedState] = useState(null);

  const getStatsDDL = useCallback((cid, sid) => {
    DDLServices.GetStats(cid)
      .then((resp) => {
        if (resp.status == 200) {
          if (sid) {
            setValue("StateId", sid);
            setSelectedState(sid);
          }

          setStatesDDL(resp.data);
        }
      })
      .catch((err) => {});
  });

  const getDocTypeDDL = useCallback((did) => {
    DDLServices.GetDocType()
      .then((resp) => {
        if (resp.status == 200) {
          setDocTypeDDL(resp.data.result || []);
          did && setValue("DocTypeId", did);
        }
      })
      .catch((err) => {});
  });

  const [attachmentList, setAttachmentList] = useState(null);

  const getDocumentDataById = useCallback(() => {
    DocumentServices.GetDocumentById(doc.Id)
      .then((resp) => {
        if (resp.status == 200) {
          const res = resp.data.result;
          setDocData(res);

          // formData.append("DocName", data?.DocName);
          // formData.append("ExpiryDate", data?.ExpiryDate);
          // formData.append("IssueDate", data.IssueDate);
          // formData.append("ValidFor", data?.ValidFor || "");
          // formData.append("DocTypeId", data?.DocTypeId);
          // formData.append("FolderId", data?.FolderId);
          // formData.append("DocOwnerName", data?.DocOwnerName || null);
          // formData.append("DocNumber", data?.DocNumber || "");
          // formData.append("CountryId", data?.CountryId || "");
          // formData.append("StateId", data?.StateId || "");
          // formData.append("RemindMe", data?.RemindMe);
          // formData.append("remindOn", remindOn);
          // formData.append("ValidForPeriod", data?.ValidForPeriod || "");
          setValue("Id", res?.Id);
          setValue("DocName", res?.DocName);
          setValue("ExpiryDate", res.ExpiryDate);
          // setValue("DocTypeId", res.DocTypeId);
          // setValue("FolderId", res.FolderId);
          setValue("DocNumber", res.DocNumber);
          // if (CountriesDDL) {
          //   setValue("CountryId", DocData?.CountryId);
          //   console.log(CountriesDDL, { doc });
          // }

          // getStatsDDL(res.CountryId, res.StateId);
          // setValue("StateId", res.StateId);
          setValue("RemindMe", res.RemindMe);
          setAttachmentList(res.attachmentList);
          // setValue("", res.);

          getUserFoldersDDL(res.FolderId);
          getDocTypeDDL(res.DocTypeId);
          geCountriesDDL(res.CountryId, res.StateId);
        }
      })
      .catch((err) => {
        // toast.error("Failed");
      });
  });

  const {
    register,
    handleSubmit,
    errors,
    watch,
    loading,
    reset,
    setValue,
    CreateDocumentSubmitHandler,
    UpdateDocumentSubmitHandler,
    control,
  } = useDocumentSubmit(uploadedFiles, reminderToggle, reminders);

  const MaxFileUploadCount = 2;

  const forwardTab = (step) => {
    let temp = [...activeTabs];
    temp.push(step);
    setSelectedForm(step);
    setActiveTabs(temp);
  };

  const backwardTab = (step) => {
    let temp = [...activeTabs];
    temp.pop();
    setSelectedForm(step);
    setActiveTabs(temp);
  };

  return (
    <>
      <NavBar />
      <PricePlanModel />

      <div className="max-w-screen-xl  mx-auto py-12">
        <div className="h-full bg-gray1 ">
          <div className="mb-5 px-5 md:px-1">
            <H2 className="font-medium">Add New Document</H2>

            {/* //NOTE -  breadcrumbs */}
            <div className="text-sm breadcrumbs">
              <ul>
                <li>
                  <a>Home</a>
                </li>
                <li>
                  <a>Add New</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${cs.FlexDoc}  `}>
            <div className={`${cs.doc_left}  bg-white`}>
              <ul className="steps steps-vertical">
                {/* step-info */}
                <li
                  data-content={`${activeTabs.includes(2) ? "✔" : "1"}  `}
                  className={`${
                    activeTabs.includes(1) && "step-primary"
                  }  step`}
                >
                  <div className="flex flex-col items-start justify-start ml-5">
                    <H3 className="font-sans font-medium ">Document Details</H3>
                    <Text3 className="text-primary4 font-sans ">
                      Basic information about document
                    </Text3>
                  </div>
                </li>
                <li
                  data-content={`${activeTabs.includes(2) ? "✔" : "2"}  `}
                  className={`${
                    activeTabs.includes(2) && "step-primary"
                  }  step`}
                >
                  <div className="flex flex-col items-start justify-start ml-5">
                    <H3 className="font-sans font-medium ">Set Reminders</H3>
                    <Text3 className="text-primary4 font-sans">
                      Choose when you want heads-up
                    </Text3>
                  </div>
                </li>
                <li
                  data-content={`${activeTabs.includes(3) ? "✔" : "3"}  `}
                  className={`${
                    activeTabs.includes(3) && "step-primary"
                  }  step`}
                >
                  <div className="flex flex-col items-start justify-start ml-5">
                    <H3 className="font-sans font-medium ">Upload Document</H3>
                    <Text3 className="text-primary4 font-sans">
                      Attach document for future reference
                    </Text3>
                  </div>
                </li>
              </ul>
            </div>
            <form
              onSubmit={handleSubmit(
                DocData
                  ? UpdateDocumentSubmitHandler
                  : CreateDocumentSubmitHandler
              )}
              className={`${cs.doc_right}  bg-white`}
            >
              {/* //NOTE - Document Details */}
              <div
                style={{ display: selectedForm === 1 ? "block" : "none" }}
                className="relative w-full"
              >
                <div className="flex flex-col justify-start items-start">
                  <p className="text-[26px] text-gray5 font-bold font-sans">
                    Document Details
                  </p>
                  <p className="text-[16x] text-gray3 font-medium font-sans">
                    Add basic information about the document.
                  </p>
                </div>

                <div>
                  <div className="mt-[32px] ">
                    <LabelDoc id="DocName" label="Document Name" />
                    <input
                      type="text"
                      {...register("DocName")}
                      id="DocName"
                      className="x-doc-input"
                      placeholder=""
                    />
                    <Error errorName={errors.DocName} />
                  </div>
                  <div className="mt-[32px] ">
                    <LabelDoc id="ExpiryDate" label="Expiration Date" />

                    <Controller
                      control={control}
                      {...register("ExpiryDate")}
                      id="ExpiryDate"
                      name="ExpiryDate"
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="Select Expiry date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          className="x-doc-input"
                        />
                      )}
                    />

                    <Error errorName={errors.ExpiryDate} />
                  </div>

                  <div className="flex items-start  mt-[32px]">
                    <div className="flex items-center h-5">
                      <input
                        id="isAgree"
                        // {...register("isAgree")}
                        onChange={(e) => setHaveDate(e.target.checked)}
                        type="checkbox"
                        value=""
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                      />
                    </div>

                    <label
                      htmlFor="isAgree"
                      className="ms-2 text-sm font-medium text-gray3 dark:text-gray-300"
                    >
                      I have issue date and validity period.
                    </label>
                  </div>

                  {HaveDate && (
                    <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px]  mt-[32px] ">
                      <div className=" w-full">
                        <LabelDoc id="document_name" label="Issue Date" />
                        <input
                          type="date"
                          // {...register("username")}
                          id="document_name"
                          className="x-doc-input"
                          placeholder=""
                        />
                      </div>
                      <div className="custom-select w-full">
                        <LabelDoc id="document_name" label="Valid for" />
                        <select
                          className="x-doc-input"
                          {...register("ValidFor")}
                          // value={""}
                          //  onChange={handleChange}
                        >
                          <option value="">Select Duration</option>
                          <option value="60">02 Months</option>
                          <option value="120">04 Months</option>
                          <option value="180">06 Months</option>
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px]  mt-[32px] ">
                    <div className="custom-select w-full">
                      <div className="flex justify-between">
                        <LabelDoc id="FolderId" label="Add to Folder" />
                        <Link
                          to="/folders"
                          className="text-[12px] font-medium text-blue-400 hover:underline"
                        >
                          Create new folder
                        </Link>
                      </div>
                      <select
                        {...register("FolderId")}
                        id="FolderId"
                        className="x-doc-input"
                        // value={""}
                        //  onChange={handleChange}
                      >
                        <option value="">Select Folder</option>

                        {UserFoldersDDL &&
                          UserFoldersDDL?.map((item) => (
                            <option value={item?.Id}>{item?.Name}</option>
                          ))}
                      </select>
                      <Error errorName={errors.FolderId} />
                    </div>
                    <div className=" w-full"></div>
                  </div>

                  {/* //NOTE -  */}
                  <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px]  mt-[32px] ">
                    <div className="custom-select w-full">
                      <LabelDoc id="CountryId" label="Issued Country" />
                      <select
                        {...register("CountryId")}
                        id="CountryId"
                        className="x-doc-input"
                        // value={""}
                        onChange={(e) => getStatsDDL(e.target.value, false)}
                      >
                        <option value="">Select Country</option>

                        {CountriesDDL &&
                          CountriesDDL?.map((country) => (
                            <option value={country}>{country}</option>
                          ))}
                      </select>
                      <Error errorName={errors.CountryId} />
                    </div>
                    <div className="custom-select w-full">
                      <LabelDoc id="StateId" label="Issued City" />
                      <select
                        {...register("StateId")}
                        id="StateId"
                        className="x-doc-input"
                        placeholder=""
                        value={DocData && selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                      >
                        <option value="">Select City</option>
                        {StatesDDL &&
                          StatesDDL?.map((states) => (
                            <option value={states}>{states}</option>
                          ))}
                      </select>
                      <Error errorName={errors.StateId} />
                    </div>
                  </div>

                  <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px]  mt-[32px] ">
                    <div className="custom-select w-full">
                      <LabelDoc id="DocTypeId" label="Document Type" />
                      <select
                        {...register("DocTypeId")}
                        id="DocTypeId"
                        className="x-doc-input"
                      >
                        <option value="">Select Type</option>

                        {DocTypeDDL &&
                          DocTypeDDL?.map((item) => (
                            <option value={item?.Id}>{item?.Title}</option>
                          ))}
                      </select>
                      <Error errorName={errors.DocTypeId} />
                    </div>
                    <div className=" w-full">
                      <LabelDoc id="DocNumber" label="Document Number" />
                      <input
                        type="text"
                        {...register("DocNumber")}
                        id="DocNumber"
                        className="x-doc-input"
                        placeholder=""
                      />
                      <Error errorName={errors.DocNumber} />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end gap-[20px]  mt-[32px] ">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        !errors.DocNumber &&
                        !errors.DocTypeId &&
                        !errors.FolderId &&
                        !errors.ExpiryDate &&
                        !errors.DocName
                      ) {
                        forwardTab(2);
                      } else {
                        toast.error("Please input required fields first!");
                      }
                    }}
                    className="btn btn-info w-[90px] text-white font-medium  "
                  >
                    Next
                  </button>
                </div>
              </div>

              {/* //NOTE - Set Reminders */}
              <div
                style={{ display: selectedForm === 2 ? "block" : "none" }}
                className="relative w-full"
              >
                <div className="flex flex-col justify-start items-start">
                  <p className="text-[26px] text-gray5 font-bold font-sans">
                    Set Reminders
                  </p>
                  <p className="text-[16x] text-gray3 font-medium font-sans">
                    Schedule additional reminders for renewal
                  </p>
                </div>
                <p className="text-[16px] text-gray5 font-semibold mt-[45px] mb-[18px] ">
                  Following Reminders as set by default
                </p>
                <section className={cs.flex_reminder}>
                  <div className={cs.reminder_card}>
                    <img
                      src={images.bell_icon}
                      alt="save"
                      className="mr-[9px]"
                    />
                    <div>
                      <p className="text-gray3 text-[12px] font-semibold ">
                        02 Weeks
                      </p>
                      <p className="text-gray3 text-[10px]">
                        before expiration
                      </p>
                    </div>
                  </div>
                  <div className={cs.reminder_card}>
                    <img
                      src={images.bell_icon}
                      alt="save"
                      className="mr-[9px]"
                    />
                    <div>
                      <p className="text-gray3 text-[12px] font-semibold ">
                        04 Weeks
                      </p>
                      <p className="text-gray3 text-[10px]">
                        before expiration
                      </p>
                    </div>
                  </div>

                  <div className={cs.reminder_card}>
                    <img
                      src={images.bell_icon}
                      alt="save"
                      className="mr-[9px]"
                    />
                    <div>
                      <p className="text-gray3 text-[12px] font-semibold ">
                        04 Months
                      </p>
                      <p className="text-gray3 text-[10px]">
                        before expiration
                      </p>
                    </div>
                  </div>

                  <div className={cs.reminder_card}>
                    <img
                      src={images.bell_icon}
                      alt="save"
                      className="mr-[9px]"
                    />
                    <div>
                      <p className="text-gray3 text-[12px] font-semibold ">
                        06 Months
                      </p>
                      <p className="text-gray3 text-[10px]">
                        before expiration
                      </p>
                    </div>
                  </div>
                </section>
                <div className="flex flex-col justify-start items-start mt-[45px]">
                  <div className="flex ">
                    <p className="text-[16px] text-gray5 font-bold font-sans mr-6">
                      Custom Reminders
                    </p>
                    <input
                      type="checkbox"
                      {...register("RemindMe")}
                      class="toggle toggle-sm [--tglbg:white] bg-primary4 hover:bg-primary7 border-primary4"
                      checked={reminderToggle}
                      onChange={(e) => setreminderToggle(e.target.checked)}
                    />
                  </div>

                  <p className="text-[16px] text-gray3 font-medium font-sans mt-1">
                    Add up to three reminders
                  </p>
                </div>

                {reminderToggle && (
                  <>
                    <p className="text-[14px] text-gray3 font-medium font-sans mt-10 ">
                      Day/s Before Expiration
                    </p>
                    {reminders.map((reminder, index) => (
                      <div
                        key={index}
                        className="flex flex-wrap lg:flex-nowrap justify-between gap-[20px] mt-[16px]"
                      >
                        <div className="w-[50%]">
                          <input
                            type="number"
                            min={1}
                            className="x-doc-input"
                            placeholder=""
                            defaultValue={reminder.CustomRemindValue}
                            onChange={(e) =>
                              handleValueChange(index, e.target.value)
                            }
                          />
                        </div>
                        <div className="w-[30%]">
                          <select
                            className="x-doc-input"
                            defaultValue={reminder.CustomRemindPeriod}
                            onChange={(e) =>
                              handleUnitChange(index, e.target.value)
                            }
                          >
                            <option value="" disabled selected>
                              Select Period
                            </option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                          </select>
                        </div>
                        <div className="flex w-[20%]">
                          <div
                            style={{
                              display:
                                reminders.length < 3 &&
                                index === reminders.length - 1
                                  ? "flex"
                                  : "none",
                              cursor: isDefaultReminder && "not-allowed",
                            }}
                            onClick={addReminder}
                            className="cursor-pointer relative bg-blue-50 hover:bg-blue-100 border-2 border-white rounded-lg inline-flex items-center justify-center w-[60px] h-[54px] overflow-hidden bg-gray-600 dark:bg-gray-600"
                          >
                            <span className="font-medium text-[20px] text-blue2 dark:text-gray-300">
                              +
                            </span>
                          </div>
                          <div
                            className="w-[60px] h-[54px] flex justify-center items-center"
                            style={{
                              display:
                                index !== 0 && index === reminders.length - 1
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <img
                              src={images.cross_icon}
                              alt="remove"
                              className="w-[20px] h-[20px] hover:w-[25px] hover:h-[25px] duration-300 ease-in-out cursor-pointer"
                              onClick={() => removeReminder(index)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {isDefaultReminder && (
                      <p className="text-[14px] text-xred font-medium font-sans mt-3 ">
                        This is already default reminder
                      </p>
                    )}
                  </>
                )}

                <div className="flex flex-wrap justify-between mt-[75px] ">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      backwardTab(1);
                    }}
                    className="btn btn-info bg-primary8 w-[90px] text-primary7 font-medium outline-none border-none  hover:bg-gray1 "
                  >
                    Back
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      forwardTab(3);
                    }}
                    disabled={isDefaultReminder}
                    className="btn btn-info w-[90px] text-white font-medium  "
                  >
                    Next
                  </button>
                </div>
              </div>

              {/* //NOTE - Upload Image */}
              <div
                style={{ display: selectedForm === 3 ? "block" : "none" }}
                className="relative w-full "
              >
                <div className="flex flex-col justify-start items-start mb-[40px]">
                  <p className="text-[26px] text-gray5 font-bold font-sans">
                    Upload Document
                  </p>
                  <p className="text-[16x] text-gray3 font-medium font-sans">
                    Attach your document for future reference
                  </p>
                </div>

                <div className="flex flex-wrap gap-5">
                  {attachmentList &&
                    attachmentList.length > 0 &&
                    attachmentList.map((file, index) => (
                      <div
                        key={index}
                        className="relative w-[200px] h-[200px] mb-5 border border-slate-200 rounded-xl flex flex-col items-center justify-center "
                      >
                        {/* <img
                          src={images.cross_icon}
                          alt="save"
                          className="absolute top-2 right-2 cursor-pointer"
                          onClick={() => {
                            let temp = [...uploadedFiles];
                            temp.splice(index, 1);
                            setUploadedFiles(temp);
                          }}
                        /> */}
                        <img
                          src={
                            file?.FileExtention == ".pdf"
                              ? images.pdf
                              : images.file
                          }
                          alt="save"
                          className="w-[62px] h-[62px] mb-[26px]"
                        />
                        <p
                          title={file?.FileName}
                          className="text-gray5 text-[16px] font-bold"
                        >
                          {file?.FileName}
                        </p>
                        <p className="text-primary4 text-[14px] mt-1">
                          {bytesToMB(file.Size)} MB
                        </p>
                      </div>
                    ))}

                  {attachmentList &&
                    attachmentList.length > 0 &&
                    uploadedFiles.length < MaxFileUploadCount && (
                      <Dropzone
                        accept={{
                          "image/*": [".png", ".jpg", ".jpeg", ".gif"],
                          "application/pdf": [".pdf"],
                          "image/svg+xml": [".svg"],
                        }}
                        onDrop={(acceptedFiles) => {
                          // console.log(setUploadedFiles(acceptedFiles))
                          let temp = [...uploadedFiles];
                          temp.push(acceptedFiles[0]);
                          setUploadedFiles(temp);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="w-[200px] h-[200px] cursor-pointer border-dashed border-2 border-blue-300 bg-blue-50 rounded-xl flex flex-col items-center justify-center "
                          >
                            <input {...getInputProps()} />
                            <img
                              src={images.uploadFile}
                              alt="save"
                              className="w-[62px] h-[62px] mb-[26px]"
                            />
                            <p className="text-gray5 text-[16px] font-bold">
                              File Uploader
                            </p>
                            <p className="text-primary4 text-[14px] mt-1">
                              Drag & Drop files here
                            </p>
                          </div>
                        )}
                      </Dropzone>
                    )}
                </div>

                <div className="flex flex-wrap gap-5">
                  {uploadedFiles.length > 0 &&
                    uploadedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="relative w-[200px] h-[200px] border border-slate-200 rounded-xl flex flex-col items-center justify-center "
                      >
                        <img
                          src={images.cross_icon}
                          alt="save"
                          className="absolute top-2 right-2 cursor-pointer"
                          onClick={() => {
                            let temp = [...uploadedFiles];
                            temp.splice(index, 1);
                            setUploadedFiles(temp);
                          }}
                        />
                        <img
                          src={images.pdf}
                          alt="save"
                          className="w-[62px] h-[62px] mb-[26px]"
                        />
                        <p
                          title={file.path}
                          className="text-gray5 text-[16px] font-bold"
                        >
                          {file.path}
                        </p>
                        <p className="text-primary4 text-[14px] mt-1">
                          {bytesToMB(file.size)} MB
                        </p>
                      </div>
                    ))}

                  {uploadedFiles.length > 0 &&
                    uploadedFiles.length < MaxFileUploadCount && (
                      <Dropzone
                        accept={{
                          "image/*": [".png", ".jpg", ".jpeg", ".gif"],
                          "application/pdf": [".pdf"],
                          "image/svg+xml": [".svg"],
                        }}
                        onDrop={(acceptedFiles) => {
                          // console.log(setUploadedFiles(acceptedFiles))
                          let temp = [...uploadedFiles];
                          temp.push(acceptedFiles[0]);
                          setUploadedFiles(temp);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="w-[200px] h-[200px] cursor-pointer border-dashed border-2 border-blue-300 bg-blue-50 rounded-xl flex flex-col items-center justify-center "
                          >
                            <input {...getInputProps()} />
                            <img
                              src={images.uploadFile}
                              alt="save"
                              className="w-[62px] h-[62px] mb-[26px]"
                            />
                            <p className="text-gray5 text-[16px] font-bold">
                              File Uploader
                            </p>
                            <p className="text-primary4 text-[14px] mt-1">
                              Drag & Drop files here
                            </p>
                          </div>
                        )}
                      </Dropzone>
                    )}
                </div>

                {uploadedFiles.length === 0 && (
                  <Dropzone
                    accept={{
                      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
                      "application/pdf": [".pdf"],
                      "image/svg+xml": [".svg"],
                    }}
                    onDrop={(acceptedFiles) => {
                      console.log(acceptedFiles[0]);
                      let temp = [...uploadedFiles];
                      temp.push(acceptedFiles[0]);
                      setUploadedFiles(temp);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="border-dashed border-blue-300 bg-blue-50 rounded-lg border-2  py-[17px] px-[25px]">
                        <div className="cursor-pointer" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="flex">
                            <img
                              src={images.uploadFile}
                              alt="save"
                              className="mr-[16px]"
                            />
                            <div>
                              <p className="font-medium text-[16px] text-primary2 ">
                                Quick file uploader
                              </p>
                              <p className=" text-[16px] text-gray3 ">
                                Drag & Drop or choose up to 2 files (max 03 MB
                                size) <br />
                                from your computer
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}

                {uploadedFiles.length === MaxFileUploadCount && (
                  <section className="flex flex-wrap justify-between border-dashed border-blue-300 bg-blue-50 rounded-lg border-2  py-[17px] px-[25px] mt-[32px] ">
                    <div className="flex flex-wrap">
                      <img
                        src={images.uploadFile}
                        alt="save"
                        className="mr-[16px]"
                      />
                      <div>
                        <p className="font-medium text-[16px] text-primary2 ">
                          Upload more files
                        </p>
                        <p className=" text-[16px] text-gray3 ">
                          You are currently using basic plan
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() =>
                        document
                          .getElementById("upgrade_plan_modal")
                          .showModal()
                      }
                      className="rounded-[6px] w-[115px] text-white bg-xgreen border-none  font-sans font-medium text-[12px] py-[10px] px-[16px]"
                    >
                      Upgrade Now
                    </button>
                  </section>
                )}

                <div className="flex flex-wrap justify-between mt-[75px] ">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      backwardTab(2);
                    }}
                    className="btn btn-info bg-primary8 w-[90px] text-primary7 font-medium outline-none border-none  hover:bg-gray1 "
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-info w-[90px] text-white font-medium  "
                  >
                    {loading ? (
                      <span className="loading loading-spinner"></span>
                    ) : DocData ? (
                      "Update "
                    ) : (
                      "Finish"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDocuments;
