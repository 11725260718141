import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createTaskValidation } from "../helpers/validations";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import TaskServices from "../services/api/task.service";
import Swal from "sweetalert2";

const useTaskSubmit = (id, getTask, TaskData) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(createTaskValidation),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  // Updating values after component mounts or some event

  const CreateTaskSubmitHandler = (data) => {
    if (TaskData?.length >= 2) {
      document.getElementById("create_task_modal").close();
      Swal.fire({
        title: "Upgrade",
        text: "You are current using Xpiree basic plan. Upgrade to add more task.",
        icon: "info",
      });
      return;
    }
    const payload = {
      ...data,
      DocId: id,
      AssignTo: data.AssignTo || null,
    };
    setLoading(true);
    TaskServices.createTask(payload)
      .then((resp) => {
        setLoading(false);
        if (resp.status == 200) {
          toast.success("Task Created Successfully!");

          reset();
          document.getElementById("create_task_modal").close();
          getTask();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Failed");
        document.getElementById("create_task_modal").close();
      });
  };

  return {
    register,
    handleSubmit,
    errors,
    isValid,
    CreateTaskSubmitHandler,
    loading,
    reset,
    setValue,
  };
};

export default useTaskSubmit;
