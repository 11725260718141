import { auth } from "../interfaces/api-urls";
import { getRequest, postRequest } from "../interfaces/axios-methods";

const AuthServices = {
  loginUser: async (body) => {
    return postRequest(auth.LOGIN_API_URL, body);
  },

  signUpUser: async (body) => {
    return postRequest(auth.SIGNUP_API_URL, body);
  },

  forgetPassword: async (email) => {
    return getRequest(auth.FORGET_PASSWORD + "?email=" + email);
  },

  getUserInfo: async () => {
    return getRequest(auth.GET_USER_INFO);
  },

  confirmEmail: async (userId, token) => {
    return getRequest(
      auth.CONFIRM_EMAIL + "?userId=" + userId + "&token=" + token
    );
  },

  // async function loginUser(values) {
  //   try {
  //     const response = await postRequest(LOGIN_API_URL, {
  //       userName: values.email,
  //       password: values.password,
  //       rememberMe: values.rememberMe,
  //       loginSource: 1,
  //       //   values.loginSource
  //     });
  //     // console.log({ response });
  //     return await response;
  //   } catch (error) {
  //     return await error;
  //   }
  // }
};
// export { loginUser };
export default AuthServices;
