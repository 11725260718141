import { useCallback } from "react";
import SharerServices from "../services/api/sharer.service";

const useSharerData = (setData) => {
  const getSharerData = useCallback(() => {
    SharerServices.GetSharers().then((resp) => {
      if (resp.status === 200) {
        setData(resp.data.result?.Item1);
      }
    });
    //   .catch((err) => {
    //     setError(err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }, [setData]);

  return { getSharerData };
};

export default useSharerData;
