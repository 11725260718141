import React from "react";
import dayjs from "dayjs";
import cs from "./infoboard.module.scss";
import { images } from "../../../helpers/images";
import {
  H2,
  H3,
} from "../../../components/common/loader/TypoGraphy/TypoGraphy";
import Pricing from "../../../components/Pricing/Pricing.jsx";
import PricePlanModel from "../../../components/PricePlanModal/PricePlanModel.jsx";
import DocumentServices from "../../../services/api/document.service.js";
import { toast } from "react-toastify";

const InfoBoard = ({ doc, getDocumentDataById }) => {
  const UpdateDocStatus = () => {
    console.log(doc);
    const payload = {
      id: doc.Id,
      status: doc?.DocUserStatusId == 1 ? 2 : 1,
    };
    // setLoading(true);
    DocumentServices.updateUserDocStatus(payload)
      .then((resp) => {
        // setLoading(false);
        if (resp.status == 200) {
          toast.success("Document status updated!");
          getDocumentDataById();
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  return (
    <div className={cs.info_board}>
      <div
        className={`${cs.info_board_left} flex flex-row flex-wrap justify-between`}
      >
        <div className={`flex flex-row flex-wrap justify-center`}>
          <div
            className={`${cs.imgDiv} w-[160px] h-[160px] flex flex-col justify-center items-center`}
          >
            <img src={images.caution} alt="img" className="w-[56px] h-[56px]" />
          </div>

          <div className="px-[30px]">
            <div className="flex flex-col items-center sm:items-start mt-5 sm:mt-0">
              <p className="h2-text">{doc?.DocName}</p>
              <p className="p2-text mt-1">{doc?.FolderName}</p>
            </div>
            <div className="flex flex-wrap justify-center items-center md:justify-start mt-[20px] gap-[20px]">
              <div className="w-[170px] h-[70px] px-[15px] py-2 border-2 border-dashed border-primary6 rounded-lg ">
                <H3 className="text-xred">
                  {dayjs(doc?.ExpiryDate).format("MMM D, YYYY")}
                </H3>
                <p className="p2-text ">Expiry Date</p>
              </div>
              <div className="w-[170px] h-[70px] px-[15px] py-2 border-2 border-dashed border-primary6 rounded-lg ">
                <H3 className="text-xyellow">{doc?.DiffTotalDays} day/s </H3>
                <p className="p2-text ">Renewal Overdue</p>
              </div>
            </div>
          </div>

          <div className="flex items-end py-3">
            {/* <button className="btn btn-circle bg-blue1 border-none">
            <p className="text-[30px] text-white">+</p>
          </button>
          <p
            className="p2-text ml-3"
            style={{ color: "#3699FF", fontWeight: 600, lineHeight: "50px" }}
          >
            Add sharer
          </p> */}
            {/* <div class="flex -space-x-2 rtl:space-x-reverse">
              <img
                class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                alt=""
              />
              <img
                class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
                alt=""
              />
              <div class="relative bg-blue3 border-2 border-white rounded-full inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full dark:bg-gray-600">
                <span class="font-medium text-lg text-white dark:text-gray-300">
                  {" "}
                  +
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <div
          className=" flex flex-row flex-wrap justify-between"
          data-tip="hello"
        >
          <div
            className="tooltip tooltip-info"
            data-tip="Click to update doc status"
          >
            <button
              onClick={UpdateDocStatus}
              className={`rounded-[6px] h-[42px] py-[0] w-[160px] text-white font-sans font-medium text-[15px] ${
                doc?.DocUserStatusId === 2 ? "bg-xyellow" : "bg-blue1"
              }`}
            >
              {doc?.DocUserStatusId == 2
                ? "Pending Renewal"
                : "Mark as Renewed"}
            </button>
          </div>
          <div
            onClick={() =>
              document.getElementById("update_expiration_modal").showModal()
            }
            className={cs.menu_icon}
          >
            <img src={images.menu_vertical_icon} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBoard;
