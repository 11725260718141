import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import loadingReducer from "./features/loadingSlice";
import authReducer from "./features/authSlice";
import folderReducer from "./features/folderSlice";
import sharerReducer from "./features/sharerSlice";
import userReducer from "./features/userSlice";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: [],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    folder: folderReducer,
    sharer: sharerReducer,
    user: userReducer,
  })
);

// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     loading: loadingReducer,
//   },
// });

const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk],
});

const persistor = persistStore(store);
export { store, persistor };
