import React, { useCallback, useEffect, useState } from "react";
import {
  GetActiveDocDataTable,
  GetDashboardCounts,
  GetExpiredDocDataTable,
  GetExpiringDocDataTable,
} from "../services/api/dashboard.service";
import TaskServices from "../services/api/task.service";

const useDashboard = () => {
  const [dashboardCount, setdashboardCount] = useState(null);
  const [expiredDocData, setExpiredDocData] = useState(null);
  const [expiringDocData, setExpiringDocData] = useState(null);
  const [activeDocData, setActiveDocData] = useState(null);
  const [taskCompleted, setTaskompleted] = useState([]);

  const getDashboardCounts = useCallback(async () => {
    const resp = await GetDashboardCounts();
    setdashboardCount(resp.result);
  }, []);

  const getExpiredDocDataTable = useCallback(async () => {
    const resp = await GetExpiredDocDataTable();
    setExpiredDocData(resp.result);
  }, []);

  const getExpiringDocDataTable = useCallback(async () => {
    const resp = await GetExpiringDocDataTable();
    setExpiringDocData(resp.result);
  }, []);
  const getActiveDocDataTable = useCallback(async () => {
    const resp = await GetActiveDocDataTable();
    setActiveDocData(resp.result);
  }, []);

  const getCompletedTaskData = useCallback(() => {
    TaskServices.GetCompletedTask()
      .then((resp) => {
        if (resp?.status == 200) {
          setTaskompleted(resp.data.result);
        }
      })
      .catch((err) => {});
  });

  useEffect(() => {
    getDashboardCounts();
    getExpiredDocDataTable();
    getExpiringDocDataTable();
    getActiveDocDataTable();
    getCompletedTaskData();
  }, []);

  return {
    dashboardCount,
    expiredDocData,
    expiringDocData,
    activeDocData,
    taskCompleted,
  };
};

export default useDashboard;
