import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sharer: null,
};

const sharerSlice = createSlice({
  name: "sharer",
  initialState: initialState,
  reducers: {
    // actions will come here
    SelectSharer: (state, action) => {
      state.sharer = action.payload;
    },
    ClearSharer: (state) => {
      state.sharer = null;
    },
  },
});

export const { SelectSharer, ClearSharer } = sharerSlice.actions;
export default sharerSlice.reducer;
