import React, { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Loader from "./components/common/loader/Loader";
// import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import PUBLIC_ROUTES from "./routes/PublicRoutes";

function App() {
  // const isLoading = true
  // const {
  //   loading: { isLoading },
  // } = useSelector((state) => state);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        {/* {isLoading && <Loader loading={isLoading} />} */}
        <ToastContainer autoClose={2000} />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          {PUBLIC_ROUTES.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            );
          })}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
