import React, { useCallback, useEffect, useState } from "react";
import cs from "./infoboard.module.scss";
import { images } from "../../../helpers/images";
import {
  H2,
  Text1,
  Text2,
} from "../../../components/common/loader/TypoGraphy/TypoGraphy";
import formatCount from "../../../helpers/formatCount";
import AuthServices from "../../../services/api/auth.service";
import { useSelector } from "react-redux";

const InfoBoard = ({
  taskCompleted,
  dashboardCount,

  setOpenProfileSection,
}) => {
  const BaseURL = process.env.REACT_APP_BASEURL;
  let UserPic = null;

  const [UserInfo, setUserInfo] = useState(null);
  const [profilePic, setProfilePic] = useState(null);

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    // getUserData();

    const timer = setTimeout(() => {
      const userData =
        localStorage.getItem("userData") &&
        JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        setUserInfo(userData);
        UserPic = BaseURL.replace(/\/api$/, "") + "/" + userData.ProfilePic;
        if (userData.ProfilePic) {
          setProfilePic(UserPic);
        }
      }
    }, 1000); // 1000 milliseconds = 1 second

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // const getUserData = useCallback(() => {
  //   AuthServices.getUserInfo()
  //     .then((resp) => {
  //       if (resp.status == 200) {
  //         setUserInfo(resp.data);
  //         UserPic = BaseURL.replace(/\/api$/, "") + "/" + resp.data.ProfilePic;
  //         if (resp.data.ProfilePic) {
  //           setProfilePic(UserPic);
  //         }

  //         console.log(UserPic);
  //       }
  //     })
  //     .catch((err) => {});
  // });

  const setProfileSection = () => {
    // setOpenProfileSection(true);
    document.getElementById("update_profile_modal").showModal();
    // getUserData();
  };

  return (
    <div className={cs.info_board}>
      <div className={`${cs.info_board_left} flex flex-row flex-wrap`}>
        {UserInfo && (
          <div
            onClick={() => setProfileSection()}
            className={`${cs.imgDiv} w-[160px] h-[160px] flex flex-col justify-center items-center`}
          >
            {profilePic ? (
              <img
                src={profilePic}
                alt="img"
                className="w-[160px] h-[160px] object-cover"
              />
            ) : (
              <>
                <img
                  src={images.ImgPlaceholder}
                  alt="img"
                  className="w-[56px] h-[56px]"
                />
                <p className="p2-text" style={{ color: "#3699FF" }}>
                  Add your Photo
                </p>
              </>
            )}
          </div>
        )}

        {!UserInfo && (
          <div class="skeleton h-32 w-32  " data-theme="light"></div>
        )}

        <div className="px-[30px]">
          <div className="text-center md:text-start">
            <p className="h2-text">{UserInfo?.Name || ""}</p>
            <p className="p2-text mt-1">{UserInfo?.Email || ""}</p>
          </div>
          <div className="flex flex-wrap justify-center md:justify-start mt-[20px] gap-[20px]">
            <div className="w-[100%] md:w-[130px] h-[70px] px-[15px] py-1  rounded-lg bg-xred ">
              <H2 className="text-white">
                {formatCount(dashboardCount?.ExpiredDoc) || "00"}
              </H2>
              <Text2 className="text-white">Expired</Text2>
            </div>
            <div className="w-[100%] md:w-[130px]  h-[70px] px-[15px] py-1  rounded-lg bg-xyellow ">
              <H2 className="text-white">
                {formatCount(dashboardCount?.ExpiringDoc) || "00"}
              </H2>
              <Text2 className="text-white">Expiring</Text2>
            </div>
            <div className="w-[100%] md:w-[130px]  h-[70px] px-[15px] py-1  rounded-lg bg-xgreen ">
              <H2 className="text-white">
                {formatCount(dashboardCount?.ActiveDoc) || "00"}
              </H2>
              <Text2 className="text-white">Active</Text2>
            </div>
          </div>
        </div>

        <div className="flex items-end py-3">
          {/* <button className="btn btn-circle bg-blue1 border-none">
            <p className="text-[30px] text-white">+</p>
          </button>
          <p
            className="p2-text ml-3"
            style={{ color: "#3699FF", fontWeight: 600, lineHeight: "50px" }}
          >
            Add sharer
          </p> */}
          <div class="flex -space-x-2 rtl:space-x-reverse">
            {/* <img
              class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
              src="	https://flowbite.com/docs/images/people/profile-picture-2.jpg"
              alt=""
            />
            <img
              class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
              src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              alt=""
            />
            <img
              class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
              src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
              alt=""
            /> */}
            <div
              onClick={() =>
                document.getElementById("manage_sharer_modal").showModal()
              }
              class="relative bg-blue3 border-2 border-white cursor-pointer inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full dark:bg-gray-600"
            >
              <span class="font-medium text-lg text-white dark:text-gray-300">
                {" "}
                +
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={cs.info_board_right}>
        {/* Tasks Completed */}
        <p className="h2-text">Tasks Completed</p>
        {taskCompleted.length == 0 ? (
          <p className="p2-text my-1">No task completed yet</p>
        ) : (
          <p className="p2-text my-1">Completed Tasks:</p>
        )}
        {taskCompleted.length !== 0 && (
          <div className="w-full text-center">
            <div
              className="radial-progress text-green-600 font-bold text-[24px] "
              style={{
                // "--value": 70,
                "--value": 100,
                "--size": "115px",
                "--thickness": "12px",
              }}
              role="progressbar"
            >
              {taskCompleted.length}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBoard;
